import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from "@angular/core";
import { HttpService } from 'src/app/services/http.service';
import swal from 'sweetalert2';

@Injectable()
export class AuthService {

    constructor(private _http: HttpService) { }

    isAuthenticated() {
        return localStorage.getItem("user") != null;
    }

    public getCurrentUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    public getUserId() {
        return JSON.parse(localStorage.getItem("user")).id;
    }

    public getUserRole() {
        if (localStorage.getItem("user")) {
            let roles = JSON.parse(localStorage.getItem("user")).roles;
            if (roles) return roles;
            else return [];
        }
        else return [];
    }

    public getToken(): string {
        return localStorage.getItem("token");
    }

    public hasRole(rl: string): boolean {
        let res = 0;
        //Parse through authorities
        this.getUserRole().forEach(role => {
            //Check if there is a match
            if (role.authority == rl) res = 1;
        });

        return res == 1;
    }

    /**
     * Summary. Check if user has any of etablissement roles
     */
    public hasEtablissementRole(){
        return this.hasRole('ROLE_COORDONNATEUR') || this.hasRole('ROLE_GESTIONNAIRE') || this.hasRole('ROLE_RV');
    }

    /**
     * Summary. Check if user has any of Desdp roles
     */
    public hasDesdpRole(){
        return this.hasRole('ROLE_RA') || this.hasRole('ROLE_RT') || this.hasRole('ROLE_CD');
    }

    /**
     * Summary. Check if user has any of DESDPS roles
     */
    public hasAneaqRole(){
        return this.hasRole('ROLE_RA_AN') || this.hasRole('ROLE_RT_AN');
    }

    /**
     * Summary. Decode jwt token
     * @param token string
     */
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /**
     * Summary. check the token's expiry time is close
     */
    checkToken(KEEPALIVED_FREQUENCY) {
        // console.log('checkToken');
        //Get token expiry time
        let token = this.parseJwt(this.getToken());

        //Check token timestamp type => Server timestamp may change from server env to another
        let difference = KEEPALIVED_FREQUENCY * 1000;
        if (token.exp.toString().length < 13) token.exp *= 1000;

        // console.log(token.exp, new Date().getTime(), (token.exp - new Date().getTime()), difference)
        // console.log((token.exp - new Date().getTime())/1000, difference/1000)
        //Check current timestamp
        if ((token.exp - new Date().getTime()) <= difference) this.refreshToken();
    } 

    /**
     * Summary. refresh token
     */
    refreshToken() {
        this._http.get("account/refreshToken")
            .then((data: any) => {
                //Update token value
                localStorage.setItem("token", data.token);
            })
            .catch(error => {
                console.log(error)
            })
    }

    /**
     * Show disconnect alter for XXX seconds
     * @param timeout 
     */
    disconnect(timeout) {
        swal.fire({
            title: 'Alert!',
            html: 'Vous allez être déconnecté après 10 seconds...',
            timer: timeout * 1000,
            timerProgressBar: true,
        })
    }

    /**
     * Summary. Dismiss swal alert
     */
    DismissAlert(){
        swal.close();
    }
}