import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslatorService } from 'src/app/services/translator.service';
//Import SWAL alert
import swal from 'sweetalert2';


@Component({
  selector: 'app-modal-enseignant',
  templateUrl: './enseignant.component.html',
  styleUrls: ['./enseignant.component.scss']
})
export class EnseignantComponent implements OnInit {

  @Input()
  _form_enseignant: FormGroup;

  @Input()
  isOpen: boolean = false;

  @Input()
  etablissement: any;

  @Input()
  universite: any;

  @Input()
  _langue: string;

  _type: string;
  cinExist: boolean;
  isUniversitaire: boolean;
  _submitted: boolean = false;

  _universites: any = [];
  _diplomes: any = [];

  //init referentiels
  _etablissements: any = [];
  _departements: any = [];
  _grades: any = [];

  //Init eneignant status
  _status = [{
    libelle: 'Permanent',
    libelleAr: 'دائم',
    value: 'PERMANENT',
  },
  {
    libelle: 'Vaccataire',
    libelleAr: 'مؤقت',
    value: 'VACCATAIRE',
  }];

  _user: any = {};

  constructor(
    private _helper: HelperService,
    public activeModal: NgbActiveModal,
    private _http: HttpService,
    public _translator: TranslatorService,
    private el: ElementRef,
    public _auth: AuthService,
  ) {
    this._user = this._auth.getCurrentUser();
    this.addDepartement = this.addDepartement.bind(this);
  }

  async ngOnInit() {
    //Init langue
    this._translator.init();

    this._type = this._user.publicPrive;

    //Auto fill universitie if etablissement was set
    if (this._form_enseignant.get('etablissement').value && this._form_enseignant.get('etablissement').value.universite) this._form_enseignant.get('universite').setValue(this._form_enseignant.get('etablissement').value.universite.id);

    if ((this.universite || this.etablissement) && this._form_enseignant.invalid) {
      this._form_enseignant.get('appartient').setValue(true);

      this._form_enseignant.get('universite').setValue(this.universite ? this.universite.id : null);
      this._form_enseignant.get('etablissement').setValue(this.etablissement ? this.etablissement : null);
      //this.updateDepartements(true);
    }

    this.updateDepartements();

    //Init universities
    await this._InitUniversities();

    //Init diplomes
    await this._InitDiplomes();

    //Init etablissements
    this.updateEtablissements();

    //Clear unwanted fields
    // this.clearFields();
  }

  // isCoordonnateurModuleAndPublic(): boolean {
  //   return (this.isCoordonnateurModule && this._type === 'PUBLIC') ? true : false;
  // }

  /**
* Summary. check input's key down
* @param event 
*/
  checkLang(event, lang) {
    const key = event.key;
    if (lang == 'ar') {
      if (event.keyCode != 8 && event.keyCode != 37 && event.keyCode != 39 && event.keyCode != 86 && !this.isArabic(key) && event.keyCode != 32) {
        event.preventDefault();
      }
    } else if (lang == 'fr' && this.isArabic(key)) {
      event.preventDefault();
    }
  }

  isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    return pattern.test(text);
  }

  /**
   * Summary. Init universisites
   */
  async _InitUniversities() {
    await this._http.get('public/universites')
      .then((result: any) => { this._universites = result; })
  }

  /**
   * Summary. Scroll to first invalid control
   */
  scrollToFirstInvalidControl() {
    //Get first invalid input
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(".modal-body form .ng-invalid");
    if (!firstInvalidControl) return false
    const modalBody: HTMLElement = this.el.nativeElement.querySelector(".modal-body");
    modalBody.scroll({
      top: this.getTopOffset(firstInvalidControl) - 150,
      left: 0,
      behavior: "smooth"
    });
  }

  /**
   * Symmary. Implement smooth scroll
   * @param controlEl 
   */
  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  clearFields() {
    if (this._form_enseignant.value.appartient === true) {
      this._form_enseignant.get('organisme').setValue(null);
      this._form_enseignant.get('autreGrade').setValue(null);
      this._form_enseignant.get('statut').setValue('PERMANENT');
      this._form_enseignant.get('universite').setValue(this.universite ? this.universite.id : this._form_enseignant.get('universite').value);
      this._form_enseignant.get('etablissement').setValue(this.etablissement ? this.etablissement : this._form_enseignant.get('etablissement').value);
      
    } else {
      this._form_enseignant.get('universite').setValue(null);
      this._form_enseignant.get('etablissement').setValue(null);
      this._form_enseignant.get('departement').setValue(null);
      this._form_enseignant.get('autreDepartement').setValue(null);
      this._form_enseignant.get('statut').setValue('VACCATAIRE');
      this._form_enseignant.get('grade').setValue(null);
    }
  }

  async closeModal() {
    this._submitted = true;

    if (await this._ConformityCheck()) {
      this.activeModal.close(this._form_enseignant);
    }
    else this.scrollToFirstInvalidControl()
  }

  /**
   * Summary. Check if CIN exists
   */
  _checkIfCINExist() {
    return new Promise(resolve => {
      this._helper.showLoading();
      const cin = this._form_enseignant.value.cin + (this._form_enseignant.value.id ? "?exceptFor=" + this._form_enseignant.value.id : '');
      this._http.get("enseignant/checkCIN/" + cin)
        .then((result: any) => {
          this._helper.hideLoading();
          resolve(true);
        }).catch((error) => {
          this._helper.hideLoading();
          resolve(false);
        });
    });
  }

  _checkTelephone(): boolean {
    const telephone: string = this._form_enseignant.value.telephone;
    if (telephone.match(/(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/)) return false;
    else return true;
  }

  _checkEmail(): boolean {
    const email: string = this._form_enseignant.value.email;
    if (email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)) return false;
    else return true;
  }

  _checkRequiredFields(): boolean {
    if (!this._form_enseignant.value.nom || !this._form_enseignant.value.prenom || !this._form_enseignant.value.nomAr || !this._form_enseignant.value.prenomAr || !this._form_enseignant.value.cin) return true;
    else if (this._form_enseignant.value.appartient === true && (!this._form_enseignant.value.universite || !this._form_enseignant.value.etablissement || (!this._form_enseignant.value.departement && !this._form_enseignant.value.autreDepartement) ||
      // !this._form_enseignant.value.statut || 
      !this._form_enseignant.value.grade)) return true;
    else if (this._form_enseignant.value.appartient === false && (!this._form_enseignant.value.organisme || !this._form_enseignant.value.autreGrade)) return true;
    else if (this._form_enseignant.value.appartient === null) return true;
    else if (this._form_enseignant.invalid) return true;
    else return false;
  }

  async _ConformityCheck() {
    if (this._form_enseignant.value.email && this._checkEmail()) {
      this._translator.get("errors").then((result: any) => {
        swal.fire({
          icon: 'warning',
          title: result.alert,
          text: result.modals.emailErr,
        })
      });
      return false;
    }
    if (this._form_enseignant.value.telephone && this._checkTelephone()) {
      this._translator.get("errors").then((result: any) => {
        swal.fire({
          icon: 'warning',
          title: result.alert,
          text: result.modals.telErr,
        })
      });
      return false;
    }
    if (this._checkRequiredFields()) {
      this._translator.get("errors").then((result: any) => {
        swal.fire({
          icon: 'warning',
          title: result.alert,
          text: result.required_fields,
        })
      });
      return false;
    }
    if (await this._checkIfCINExist()) {
      this._translator.get("errors").then((result: any) => {
        swal.fire({
          icon: 'warning',
          title: result.alert,
          text: result.modals.cinExist,
        })
      });
      return false;
    }

    return true;
  }

  /**
   * Summary. Update etablissements on a change event
   */
  async updateEtablissements(clearForm: boolean = false) {
    if (this._form_enseignant.value.universite) {
      // Show loading
      this._helper.showLoading();

      //Init etablissements
      await this._http.get("public/etablissements?universiteId=" + this._form_enseignant.value.universite)
        .then((result: any) => {
          //Hide spinner
          this._helper.hideLoading();

          //Check if we need to clear the form
          if (clearForm) {
            //Clear etablissement value
            this._form_enseignant.controls['etablissement'].setValue(null);

            //Clear departement value
            this._form_enseignant.controls['departement'].setValue(null);

            //Clear grade value
            this._form_enseignant.controls['grade'].setValue(null);

            //Clear other refs
            this._departements = [];
            this._grades = [];
          }

          //Init Data
          this._etablissements = result;
        });

        if(this.disableEtablissement()) {
          //Set etablissement
          this._form_enseignant.controls['etablissement'].setValue(this.etablissement);

          await this.updateDepartements(true);
        }
    }
  }

  /**
   * Summary. Update departements on a change event
   */
  async updateDepartements(clearForm: boolean = false) {
    if (this._form_enseignant.value.etablissement) {
      // Show loading
      this._helper.showLoading();

      //Init search request
      await this._http.get("departements-by-etablissement/" + this._form_enseignant.value.etablissement.id)
        .then((result: any) => {
          //Hide spinner
          this._helper.hideLoading();

          //Check if we need to clear the form
          if (clearForm) {
            //Clear departement value
            this._form_enseignant.controls['departement'].setValue(null);
          }

          //Init Data
          this._departements = result;
        });

      //Init grades
      await this._http.get("grades-by-type/" + this._form_enseignant.value.etablissement.type)
        .then((result: any) => {
          //Hide spinner
          this._helper.hideLoading();

          //Check if we need to clear the form
          if (clearForm) {
            //Clear grade value
            this._form_enseignant.controls['grade'].setValue(null);
          }
          //Init Data
          this._grades = result;
        });

      //Init statut
      // if (this.isEtabDemande()) {
      //   // this._form_enseignant.get('statut').setValue('PERMANENT');
      // } else if (!this.isEtabDemande()) {
      //   // this._form_enseignant.get('statut').setValue('VACCATAIRE');
      // }
    }
  }

  // isEtabDemande(): boolean {
  //   if (this._form_enseignant.value.etablissement) {
  //     const isEtab = this._request_etablissement.id === this._form_enseignant.value.etablissement.id;
  //     if (this.isIntervenant && isEtab) {
  //       return true;
  //     }
  //   }
  //   return false;
  //   //if (this.isIntervenant && !isEtab)
  // }

  /**
   * Summary. Init all diplomes
   */
  async _InitDiplomes() {
    //Init diplomes
    await this._http.get("public/diplomes")
      .then((result: any) => { this._diplomes = result; });
  }

  createNewGrade(grade) {
    // Show Loading
    this._helper.showLoading();

    this._http.post("grades", {
      libelle: this._langue === 'ARABE' ? '' : grade,
      libelleAr: this._langue === 'ARABE' ? grade : '',
      type: this._type
    })
      .then((response: any) => {
        //Hide loading
        this._helper.hideLoading();

        this._grades = [...this._grades, response];

        //Fire success message
        swal.fire(
          'Succès!',
          'Grade enregistrée.',
          'success'
        ).then((result: any) => {
          this._form_enseignant.get('grade').setValue(response);
        });
      }).catch((error) => {
        // hide loading
        this._helper.hideLoading();
        // show error 
        this._helper.alert('Erreur', 'Une erreur est survenue lors de la création du  nouveau Grade', 'error');
      });
  }

  addDepartement(name) {
    let etablissement = this._form_enseignant.value.etablissement;
    if (etablissement) {
      return new Promise((resolveProm) => {
        let obj: any = {}
        obj.libelle = name;
        //Inject parent etablissement
        obj.etablissement = etablissement;

        //Init loading
        this._helper.showLoading();
        //Add new keyword
        this._http.post('departements', obj)
          .then((result: any) => {
            this._helper.hideLoading();
            //return value
            resolveProm({ id: result.id, libelle: obj.libelle, libelleAr: obj.libelleAr, etablissement: etablissement });
          });
      })
    }

  }

  disableUniversite(): boolean {
    return (!this.isOpen && this.universite) ? true : false;
  }

  disableEtablissement(): boolean {
    return (!this.isOpen && this.etablissement && !this._auth.hasRole('ROLE_RV')) ? true : false;
  }

}
