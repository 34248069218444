import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpService {

    constructor(private http: HttpClient) { }

    getLang() {
        return localStorage.getItem("current_lang");
    }

    post(route: string, data: any, base_url: any = false, responseType: any = 'json') {
        return new Promise((resolve, reject) => {
            this.http
                .post((base_url ? base_url : environment.base_url) + route, data, { responseType: responseType })
                .pipe(
                    catchError(error => {
                        //Check if errors referes to unauthorized access => Token expired... then redirect to login ui
                        if (error.status == 401 && location.pathname != '/access/login') {
                            //Logout
                            this.logout();

                            //Redirect to login UI
                            location.href = '/access/login';
                        }

                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        reject(error);
                        return throwError(errorMessage);
                    })
                )
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    get(route: string, isProd: boolean = true) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.base_url + route)
                .pipe(
                    catchError(error => {
                        //Check if errors referes to unauthorized access => Token expired... then redirect to login ui
                        if (error.status == 401 && location.pathname != '/access/login') {
                            //Logout
                            this.logout();

                            //Redirect to login UI
                            location.href = '/access/login';
                        }

                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        reject(error);
                        return throwError(errorMessage);
                    })
                )
                .subscribe(response => {
                    resolve(response);
                })

        });
    }

    delete(route) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.base_url + route)
                .pipe(
                    catchError(error => {
                        //Check if errors referes to unauthorized access => Token expired... then redirect to login ui
                        if (error.status == 401 && location.pathname != '/access/login') {
                            //Logout
                            this.logout();

                            //Redirect to login UI
                            location.href = '/access/login';
                        }

                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        reject(error);
                        return throwError(errorMessage);
                    })
                )
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    put(route, data) {
        return new Promise((resolve, reject) => {
            this.http.put(environment.base_url + route, data)
                .pipe(
                    catchError(error => {
                        //Check if errors referes to unauthorized access => Token expired... then redirect to login ui
                        if (error.status == 401 && location.pathname != '/access/login') {
                            //Logout
                            this.logout();

                            //Redirect to login UI
                            location.href = '/access/login';
                        }

                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        reject(error);
                        return throwError(errorMessage);
                    })
                )
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    /**
     * Summary. download binary file
     */
    async downloadBinary(url) {
        return new Promise((resolve, reject) => {
            this.http.get(url, {
                // observe: 'response',
                responseType: 'blob'
            })
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    /**
     * Summary. Download file
     */
    download(route, filename) {
        this.http.get<Blob>(environment.base_url + route, {
            observe: 'response',
            responseType: 'blob' as 'json'
        }).subscribe((resp: HttpResponse<Blob>) => {
            //Init filename
            this.downLoadFile(resp.body, "application/octet-stream", filename)
        });
    }

    /**
     * Summary. Trigger file download via a href
     * @param data 
     * @param fileName 
     */
    downloadURL(data, fileName) {
        const a: any = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    };

    /**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
    downLoadFile(data: any, type: string, filename: string) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0);
        // let pwa = window.open(url);
        // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //     alert('Please disable your Pop-up blocker and try again.');
        // }
    }

    /**
     * Summary. Upload file to storage engine
     */
    upload(route: string, data: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.base_url + route, data, { responseType: 'text' })
                .pipe(
                    catchError(error => {
                        //Check if errors referes to unauthorized access => Token expired... then redirect to login ui
                        if (error.status == 401 && location.pathname != '/access/login') {
                            //Logout
                            this.logout();

                            //Redirect to login UI
                            location.href = '/access/login';
                        }

                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        reject(error);
                        return throwError(errorMessage);
                    })
                )
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    /**
     * Summary. user logout
     */
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        location.href = '/access/login';
    }

    /**
     * Summary. Get PDF file
     */
    getPdf(route, filename) {
        return new Promise((resolve, reject) => {
            this.http.get<Blob>(environment.base_url + route, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
                .pipe(
                    catchError(error => {
                        reject(error);
                        return throwError(error);
                    })
                )
                .subscribe((resp: HttpResponse<Blob>) => {
                    let blob = new Blob([resp.body]);
                    let fileData = new File([resp.body], "filename", { type: resp.body.type });
                    let fileURL = window.URL.createObjectURL(fileData);
                    window.open(fileURL, "_blank")
                    resolve(resp);
                });
        });
    }
}