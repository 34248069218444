import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

//Import SWAL alert
import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-motif-refus',
  templateUrl: './modal-motif-refus.component.html',
  styleUrls: ['./modal-motif-refus.component.scss']
})
export class ModalMotifRefusComponent implements OnInit {

  form: FormGroup;
  submitted: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    //Init form
    this.form = new FormGroup({
      'motif': new FormControl('', Validators.required)
    });
  }

  closeModal() {
    this.submitted = true;
    if (this.ConformityCheck()) {
      this.activeModal.close(this.form.value.motif);
    }
  }

  ConformityCheck() {
    if (this.form.invalid) {
      swal.fire({
        icon: 'warning',
        title: 'Alerte!',
        text: 'Veuillez Saisir un motif',
      })
      return false;
    }
    return true;
  }

}
