import { Injectable } from "@angular/core";
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { HttpService } from './http.service';


@Injectable()
export class RapportsExpertiseGeneratorService {

    base_url = environment.base_url;
    download_url = this.base_url + 'downloadFile/';

    constructor(
        private helper: HelperService,
        private http: HttpService
    ) { }

    async generateZipFile(demande) {
        //Init loading
        this.helper.showLoading();

        //Init demandeFiliereExperts
        let demandeFiliereExperts: any[] = null;

        await this.http.get("demande-filiere-expert/not-individuel/" + demande.id).then((result: any) => { demandeFiliereExperts = result });

        //Init JZIP
        const jszip = new JSZip();

        //Init folders
        let rootFolder = jszip.folder("download");

        //Parse through demandeFiliereExperts
        for (let index = 0; index < demandeFiliereExperts.length; index++) {
            //Init doc
            const doc = demandeFiliereExperts[index];

            //Check if doc's url is not null
            if (!doc.urlRapportExpertise) continue;


            await this.http.downloadBinary(this.download_url + doc.urlRapportExpertise).then((data: any) => {
                //Save descriptif
                rootFolder.file(doc.urlRapportExpertise, data, { binary: true });
            });
        };

        //Assemble folders
        rootFolder.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            saveAs(content, "Rapports d'expertise " + demande.titre + ".zip");
        });

        //End 
        this.helper.hideLoading();
    }
}
