import { Injectable } from "@angular/core";
// import 'rxjs/add/operator/toPromise';
import { HttpService } from "./http.service";
import { HelperService } from './helper.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslatorService} from './translator.service';

interface Iparams {
    query?: string;
    start?: number;
}

@Injectable()
export class GlobalService {
    size: number = 10;
    query: string = "";
    current: number = 0;
    rows: number = 0;
    total: number;

    start: number = 0;
    end: number = 0;

  constructor(private http: HttpService, private helper: HelperService, private translator: TranslatorService) {
  }

    /**
     * Summary. Init service
     * TODO. NG REDUX is a better solution for state management
     */
    _Init() {
        this.current = 1;
        this.start = 0;
        this.end = 0;
        this.query = "";
    }

    search(route: string) {
        return new Promise((resolve, reject) => {
            this.http.get(route + "?size=" + this.size + "&page=" + (this.current - 1) + "&" + this.query)
                .then((response: any) => {
                    this.rows = response.count;
                    this.total = Math.ceil(this.rows / this.size);

                    //Update start index
                    if (this.rows == 0) this.start = 0;
                    else if (this.rows <= this.size) this.start = this.rows;
                    else this.start = ((this.current - 1) * this.size) + 1;

                    //Update end index
                    if (this.rows == 0) this.end = 0;
                    else if (this.rows <= ((this.current) * this.size)) this.end = this.rows;
                    else this.end = ((this.current) * this.size);

                    resolve(response);

                }).catch((error: HttpErrorResponse) => {

              // see login.component.ts:80 for comments
              this.translator.set_lang('fr');
              if (error.error.error_message === 'type_diplome_not_set') {
                this.translator.get('errors.type_diplome_not_set').then((errorMessage: string) => {
                  this.helper.alert('Erreur', errorMessage, 'error');
                });
              } else {
                this.helper.alert('Erreur', 'Erreur lors du traitement de votre demande', 'error');
              }
              reject();
            })
    })
}

delete (route: string) {
    return new Promise((resolve, reject) => {
        this.http.delete(route)
            .then((response: any) => {
                if (response.status == 1) {
                    resolve(response);
                } else {
                    this.helper.alert("Erreur", "Erreur lors du traitement de votre demande", 'error');
                    reject();
                }
            })
    });
}


}
