import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthService } from './auth.service';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): boolean {
        if (!this.isAuthenticated()) {
            this.router.navigate(['/access/login']);
            return false;
        }
        else return true;
    }

    isAuthenticated() {
        return localStorage.getItem("user") != null;
    }

}

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(): boolean {
        if (this.auth.isAuthenticated()) {
            this.router.navigate(['/home-user']);
            return false;
        }
        else return true;
    }
}

@Injectable()
export class HomeGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(): boolean {
        if (this.auth.isAuthenticated()) {
            this.router.navigate(['/home-user']);
            return false;
        }
        else return true;
    }
}

export interface CanExit {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanExitGuard implements CanDeactivate<CanExit> {
    canDeactivate(component: CanExit) {
        if (component.canDeactivate) {
            return component.canDeactivate();
        }
        else return true;
    }
}

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // Retrieve the allowed roles from `route.data`.
        const allowedRoles = route.data['allowedRoles'];

        //parse through allowedRoles
        let res  = allowedRoles.filter(role => {
            return this.auth.hasRole(role);
        });

        if(res.length == 0){
            this.router.navigate(['/']);
            return false;
        }
        else return true
    }
}