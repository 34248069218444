import { Component } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ENSSUP';
  KEEPALIVED_FREQUENCY = 5; //Seconds
  TIMEOUT = 10; //Seconds

  constructor(private idle: Idle, private keepalive: Keepalive, private auth: AuthService, private _http: HttpService) {
    // Sets an idle timeout of 5 seconds
    // idle.setIdle(environment.tokenValidty - 5);
    // // Sets a timeout period of 5 seconds. after 1805 seconds of inactivity, the user will be considered timed out.
    // idle.setTimeout(this.TIMEOUT);
    // // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => this.auth.DismissAlert());
    // idle.onTimeout.subscribe(() => {
    //   //Logout
    //   if (this.auth.isAuthenticated()) {
    //     // console.log('LOGOUT!')
    //     this._http.logout();
    //   }
    // });
    // idle.onIdleStart.subscribe(() => this.auth.disconnect(this.TIMEOUT));
    // // idle.onTimeoutWarning.subscribe((countdown) => console.log('You will time out in ' + countdown + ' seconds!'));

    // // Sets the ping interval
    // keepalive.interval(this.KEEPALIVED_FREQUENCY);

    // keepalive.onPing.subscribe(() => {
    //   // console.log('keepalive')
    //   if (this.auth.isAuthenticated()) this.auth.checkToken(this.KEEPALIVED_FREQUENCY)
    // });

    // this.resetIdle();
  }

  /**
   * Summary. reset Idle values
   */
  resetIdle() {
    this.idle.watch();

    //Check token
    if (this.auth.isAuthenticated()) this.auth.checkToken(this.KEEPALIVED_FREQUENCY);
  }

}
