import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslatorService } from 'src/app/services/translator.service';
//Import SWAL alert
import swal from 'sweetalert2';
import { EnseignantComponent } from '../enseignant/enseignant.component';


@Component({
  selector: 'app-coordonnateur',
  templateUrl: './coordonnateur.component.html',
  styleUrls: ['./coordonnateur.component.scss']
})
export class CoordonnateurComponent implements OnInit {

  @Input()
  _langue: string;

  submitted: boolean = false;
  form: FormGroup;
  _initiateurs: Array<any> = [];
  _enseignants: Array<any> = [];
  _departements: Array<any> = [];
  _universites: any[] = [];
  _user: any = {};
  _user_etablissement: any = {};
  newEnseignantObj: any = {};
  hide: boolean = true;

  constructor(
    private _helper: HelperService,
    private _http: HttpService,
    public activeModal: NgbActiveModal,
    public _translator: TranslatorService,
    private modalService: NgbModal,
    public _auth: AuthService,
    private el: ElementRef
  ) {
    this.addDepartement = this.addDepartement.bind(this);
  }

  async ngOnInit() {
    //Init form
    this._InitForm();

    this._user = this._auth.getCurrentUser();

    //Check if role == RV => then we need to pull initiateurs list
    if (this._auth.hasRole('ROLE_RV')) await this.getInitiateurs();

    await this.getUniversities();

    await this.getUserEtablissement();

    //Get enseignants
    await this.getEnseignants();
  }

  async getUniversities() {
    await this._http.get('public/universites')
      .then((result: any) => {
        this._universites = result;
      })
  }

  async getUserEtablissement(): Promise<void> {
    this._helper.showLoading();
    await this._http.get("etablissements/" + this._user.etablissementId).then((data: any) => {
      //Inject enseignants
      this._user_etablissement = data;
      this._helper.hideLoading();
    });
  }

  _InitForm() {
    // Init Coordonateur form 
    this.form = new FormGroup({
      'email': new FormControl("", [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ]),
      'login': new FormControl("", [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+$')
      ]),
      'telephone': new FormControl("", [
        Validators.required,
        Validators.pattern('[0-9]{10,12}$')
      ]),
      "password": new FormControl(null, Validators.required),
      "confirm": new FormControl(null, Validators.required),
      //"editPassword": new FormControl(false),
      "randomPassword": new FormControl(false),
      "enseignant": new FormControl(null, Validators.required),
      "departementId": new FormControl(null, [Validators.required]),

      "initiateur": new FormControl(null),
    });
  }

  /**
 * Summary. Get initiateurs list
 */
  async getInitiateurs(): Promise<void> {
    this._helper.showLoading();
    await this._http.get("gestionnaires?responsableValidationId=" + this._user.id).then((data: any) => {
      //Inject initiateurs
      this._initiateurs = data.map((i) => { i.fullName = i.user.firstName + ' ' + i.user.lastName; return i; });
      this._helper.hideLoading();
    })
  }

  /**
 * Summary. Get enseignants list
 */
  async getEnseignants() {
    this._helper.showLoading();
    await this._http.get("coordonnateur/getEnseignants").then((data: any) => {
      //Inject enseignants
      this._enseignants = data.map((i) => { i.fullName = i.nom + ' ' + i.prenom + ' ' + i.nomAr + ' ' + i.prenomAr; return i; });
      this._helper.hideLoading();
    });
  }

  /**
 * Update form on enseignant event change
 */
  updateForm() {
    //Update user data
    this.form.get('email').setValue(this.form.value.enseignant.email);
    this.form.get('telephone').setValue(this.form.value.enseignant.telephone);

    //Update departement
    this.updateDepartement();

    //Update departement
    if (this.form.value.enseignant.departement) {
      //Update enseignants list
      this.form.get('departementId').setValue(this.form.value.enseignant.departement.id);
    }
  }

  /**
 * Summary. Update departements on enseignants
 */
  updateDepartement() {
    //Check if this is a public etablissement
    if (this.form.value.enseignant.etablissement) {
      this._http.get("public/departements?etablissementId=" + this.form.value.enseignant.etablissement.id).then((result: any) => { this._departements = result; });
    }
  }

  /**
 * Summary. Toogle password
 */
  tooglePassword() {
    this.hide = !this.hide;
  }

  cleanPassword(event: any): void {
    if (event.target.checked) {
      this.form.get("password").clearValidators();
      this.form.get("password").updateValueAndValidity();
      this.form.get("password").setValue(null);
      this.form.get("confirm").clearValidators();
      this.form.get("confirm").updateValueAndValidity();
      this.form.get("confirm").setValue(null);
    } else {
      this.form.get("password").setValidators(Validators.required);
      this.form.get("password").updateValueAndValidity();
      this.form.get("confirm").setValidators(Validators.required);
      this.form.get("confirm").updateValueAndValidity();
    }
  }

  /**
 * Summary. Add new coordonnateur
 */
  addCoordonateur() {

  }

  closeModal() {
    this.submitted = true;
    if (this.form.valid) {
      // Show Loading
      this._helper.showLoading();

      // send request 
      this._http.post("coordonnateurs", {
        login: this.form.value.login,
        email: this.form.value.email,
        password: this.form.value.password,
        telephone: this.form.value.telephone,
        //  autreDepartement: this.form.value.autreDepartement,
        departementId: this.form.value.departementId,
        randomPassword: this.form.value.randomPassword,
        enseignantId: this.form.value.enseignant.id,
        gestionnaireId: this.form.value.initiateur ? this.form.value.initiateur.id : null
      })
        .then((result: any) => {
          //hide Loading
          this._helper.hideLoading();
          // Set Form Submitted a false
          this.submitted = false;

          //Fire success message
          swal.fire(
            'Succès!',
            'Votre coordonateur a bien été saisi merci !',
            'success'
          ).then((result) => {
            this.activeModal.close(this.form.value.enseignant);
          });

        })
        .catch(error => {
          this._helper.hideLoading();
          //Check error type
          if (error.error.detail.includes("EmailAlreadyUsedException")) this._helper.alert("Erreur", "Erreur lors de l'ajout de votre coordonnateur, Email existant", 'error');
          else if (error.error.detail.includes("UsernameAlreadyUsedException")) this._helper.alert("Erreur", "Erreur lors de l'ajout de votre coordonnateur, Login existant", 'error');
          else this._helper.alert("Erreur", "Erreur lors de l'ajout de votre coordonnateur", 'error');
        })
    }
    else this.scrollToFirstInvalidControl();
  }

  /**
 * Summary. Scroll to first invalid control
 */
  scrollToFirstInvalidControl() {
    //Get first invalid input
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(".modal-body form .ng-invalid");
    if (!firstInvalidControl) return false
    const modalBody: HTMLElement = this.el.nativeElement.querySelector(".modal-body");
    modalBody.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  /**
 * Symmary. Implement smooth scroll
 * @param controlEl 
 */
  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  /**
   * Summary. Add new departement
   * @param name 
   */
  addDepartement(name) {
    let etablissement = this.form.value.enseignant.etablissement;
    if (etablissement) {
      return new Promise((resolveProm) => {
        let obj: any = {}
        obj.libelle = name;
        //Inject parent etablissement
        obj.etablissement = etablissement;

        //Init loading
        this._helper.showLoading();
        //Add new keyword
        this._http.post('departements', obj)
          .then((result: any) => {
            this._helper.hideLoading();
            //return value
            resolveProm({ id: result.id, libelle: obj.libelle, libelleAr: obj.libelleAr, etablissement: etablissement });
          });
      })
    }
  }

  _initFormEnseignant(nom = '', prenom = '', nomAr = '', prenomAr = '', email = null, telephone = null, statut = null, cin = '', numeroSom = null, specialite = '', grade = null, autreGrade = null, etablissement = null, organisme = null, departement = null, diplome = null, autreDepartement = null, appartient = null, id = false): FormGroup {

    let form = {
      nom: new FormControl(nom, Validators.required),
      prenom: new FormControl(prenom, Validators.required),
      nomAr: new FormControl(nomAr, Validators.required),
      prenomAr: new FormControl(prenomAr, Validators.required),
      email: new FormControl(email),
      telephone: new FormControl(telephone),
      statut: new FormControl(statut),
      cin: new FormControl(cin, [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^[A-Za-z0-9]*$/)]),
      numeroSom: new FormControl(numeroSom),
      specialite: new FormControl(specialite),
      grade: new FormControl(grade),
      autreGrade: new FormControl(autreGrade),
      etablissement: new FormControl(etablissement),
      universite: new FormControl(null),
      organisme: new FormControl(organisme),
      departement: new FormControl(departement),
      diplome: new FormControl(diplome),
      autreDepartement: new FormControl(autreDepartement),
      appartient: new FormControl(appartient)
    }
    if (id) form['id'] = new FormControl(id);

    return new FormGroup(form);
  }

  newEnseignant() {
    const modalRef = this.modalService.open(EnseignantComponent, { backdrop: 'static', size: 'xl', scrollable: true });

    modalRef.componentInstance._form_enseignant = this._initFormEnseignant();
    modalRef.componentInstance._universites = this._universites;
    // modalRef.componentInstance.etablissement = this._auth.hasRole('ROLE_GESTIONNAIRE') ||
    //   ((this._auth.hasRole('ROLE_RV') || this._user.publicPrive === 'PRIVE')
    //     && !this._user_etablissement.universitaire)
    //   ? this._user_etablissement : null;
    // modalRef.componentInstance.universite = (this._auth.hasRole('ROLE_RV') || this._user.publicPrive === 'PRIVE') &&
    //   this._user_etablissement.universitaire
    //   ? this._user_etablissement.universite : null;
    modalRef.componentInstance.etablissement = this._user_etablissement.universitaire ? null : this._user_etablissement;
    modalRef.componentInstance.universite = this._user_etablissement.universite;
    modalRef.componentInstance.isOpen = false;
    modalRef.componentInstance._langue = 'FRANCAIS';

    modalRef.result.then(async (enseignant: FormGroup) => {
      //Create new enseignant
      await this.createNewEnseignant(enseignant.value);

      //Update enseignants list
      this._enseignants = [...this._enseignants, this.newEnseignantObj];

      //Inject the new enseignant
      this.form.get('enseignant').setValue(this.newEnseignantObj);

      //Update departement list
      await this.updateDepartement();

      //Update enseignant departement value
      if (this.newEnseignantObj.departement) this.form.get('departementId').setValue(this.newEnseignantObj.departement.id);

      //Update enseignant's email & tel
      this.form.get('email').setValue(this.newEnseignantObj.email);
      this.form.get('telephone').setValue(this.newEnseignantObj.telephone);

    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  async createNewEnseignant(enseignant: any) {
    //Init loading
    this._helper.showLoading();

    //FIX fasterXml bug 2.8V => Remove nested manyTone
    if (enseignant.departement) enseignant.departement.etablissement = null;

    //Init request
    await this._http.post("enseignant", enseignant).then((data: any) => {
      this.newEnseignantObj = data;
      this._helper.hideLoading();
    })
      .catch(error => {
        // hide loading
        this._helper.hideLoading();
        // hide loading
        this._helper.swal_alert("Erreur", "Erreur lors de la création d'un nouveau enseignant", "error");
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
