import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { HttpService } from 'src/app/services/http.service';
declare let drift: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() show_languages: boolean = false;
  @Output() close = new EventEmitter<boolean>();
  @Input() isOpen: boolean = false;
  menu: any;
  matMenu: any;
  user: any;

  constructor(private router: Router, private _elementRef: ElementRef, public auth: AuthService, private _http: HttpService) {
    //Init user

   }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this._elementRef.nativeElement.nextElementSibling.querySelector('app-drawer')) {
      if (!this._elementRef.nativeElement.nextElementSibling.querySelector('app-drawer').contains(targetElement) && targetElement.textContent != "menu") {
        this.isOpen = false;
        this.close.emit(this.isOpen);
      }
    }
  }

  ngOnInit() {
    this.user = this.auth.getCurrentUser();

    // if(this.user){
    //   drift.identify(this.user.email, {
    //     email: this.user.email,
    //     name: this.user.firstName + " " + this.user.lastName,
    //   });
    // }
  }

  drawerToggle() {
    this.isOpen = !this.isOpen;
    this.close.emit(this.isOpen);
  }

  logout() {
    this._http.logout();
  }

}
