import { Injectable } from "@angular/core";
import Swal, { SweetAlertIcon } from 'sweetalert2';
import * as $ from "jquery";

@Injectable()
export class HelperService {

    constructor() { }

    showLoading(): void {
        Swal.showLoading();
    }

    hideLoading(): void {
        //    $("#loader").remove();
        Swal.close();
    }

    alert(title: string, message: string, type: string = 'error', ok: string = 'Continuer'): void {
        $("body").append(`
            <div id="messages" class="active">
                <div class="container">
                    <div class="message">
                        <img src="${type == "warning" ? "assets/images/alert.svg" : type == "error" ? "assets/images/error.svg" : "assets/images/checked.svg"}" alt="warning">
                        <div class="title">${title}</div>
                        <div class="description">${message}</div>
                    </div>
                    <button onclick="document.getElementById('messages').remove();">${ok}</button>
                </div>
            </div>
        `);
    }

    swal_alert(title: string, message: string, type: SweetAlertIcon) {
        return Swal.fire({
            title: title,
            text: message,
            icon: type == 'success' ? 'success' : 'error'
        });
    }

    confirm(title: string, message: string, cb) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                cb();
            }
        });
    }

}