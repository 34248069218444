// Université
export class Universite {
  id?: number;
	libelle: string; //; //required
    code: string; //; // required unique
}
// Grade
export class Grade{
	libelle: string; // //required
}
// Coordonateur
export class Coordonateur{
    nom: string; // required,
    prenom: string; // required,
    email: string; // required unique,
    telephone: string; //,
    statut: Statut_Coordonateur;
	specialite: string; //,
    specialiteDiplome: string; //,
    departement: string; //
    diplome: Diplome ;
    grade: Grade
}
export class DemandeFiliere{
    dateAffectation: Date;
    dateAffectationRt: Date;
    langue:Langue;
    etape:Etape;
    intituleFr: string; // required,
    intituleEn: string; // required,
    intituleAr: string; // required,
    intituleAutre: string; //,
    prerequisPedagogique: Text;
    troncCommun: string; //,
    competenceAcquerir: Text;
    deboucher: Text;
    programmeSpecifique: Boolean;
    detailProgrammeSpecifique: Text;
    programmeNational: Boolean;
    detailProgrammeNational: Text;
    cooperationInternational: Boolean;
    detailCooperationInternational: Text;
    cooperationNational: Boolean;
    detailCooperationNational: Text;
    validationConseilUniversite: Boolean;
    validationConseilScientifique: Boolean;
    validationConseilEtablissement: Boolean;
    flagModification: Boolean;
    statutDemandeGestionnaire: STG;
    statutDemandeRa: STRA;
    statutDemandeRt: STRT;
    actionGestionnaire: AGE;
    actionRA: ARA;
    actionRT: ART;
    presentiel: Boolean;
    aDistance: Boolean;
    parAlternance: Boolean;
    hybride: Boolean;
    modaliteAccesEtudeDossier: Boolean;
    modaliteAccesConcoursEcrit: Boolean;
    modaliteAccesAutre: Boolean;
}
// Domaine
export class Domaine{
  id: number;
	libelle: string; // required,
    code: string; // required unique
}
// Série de Bac
export class SerieBac{
	libelle: string; // required,
    code: string; // required unique
}
// Departement de l'etablissement
export class DepartementEtablissement{
	Departement: string; //,
    Etablissement: string; //
}
// Enseignant
export class Enseignant{
  id?: number;
	libelle: string; // required,
  url: string; // required
  nom: string;
  prenom:string;
  nomAr: string;
  prenomAr: string;
  email: string ;
  telephone: string;
  statut: string;
  cin: string;
  numeroSom: string;
  specialite: string;
  grade: Grade;
  autreGrade: string;
  etablissement: Etablissement;
  organisme: string;
  departement: Departement;
  diplome: Diplome;
  autreDepartement: string;
  appartient: boolean;

}
export interface Departement {
  id:             number;
  libelle:        string;
  libelleAr:       string;
  etablissement: Etablissement;
  type:          string;
}

// Convention
export class Convention{
	libelle: string; // required,
    url: string; // required
}
// Vacataire
export class Vacataire{
	libelle: string; // required,
    url: string; // required
}
// Piece joint
export class PieceJoint{
	libelle: string; // required,
    url: string; // required,
    type: type_piece
}
// Option
export class Option{
	libelleFr: string; // required,
    libelleEn: string; // required,
    libelleAR: string; // required,
    libelleAutre: string;
}
// Keyword
export class Keyword{
	libelle: string; // required
}
// Sous domaine
export class SousDomaine{
	libelle: string; // required,
    code: string; // required unique
}
// Discipline
export class Discipline{
	libelle: string; // required unique,
    code: string; // required unique
}
// Visa
export class Visa{
	libelle: string; // required,
    url: string; // required
}
// Demande modification
export class DemandeModification{
	date: Date;
    statut: ResultatTR;
}
// Diplome
export class Diplome{
	libelle: string; // required,
    code: string; // required unique,
    type: type_etablissement;
    duree:number;
    troncCommunRequis: boolean;
}
export class Etablissement{
  id?:number;
	libelle: string; // required,
    code: string; // required unique,
    universitaire: Boolean;
    type: type_etablissement;
    universite:Universite;
}

export class Gestionnaire{}

export class TraitementDemande{
	date: Date;
    resultatTraitement: ResultatTR;
    avisVisa: AVIS;
    observation: Text;
    validationRA: ResultatTR;
    dateValidationRA: Date;
}
export class ResponsableAffectation{
	code: string; // required
}

export class ResponsableTraitement{
	code: string; //
}

export class AffectationSystem{
	type: type_etablissement;
    codeEtablissement: string; // required,
    codeDiplome: string; // required,
    codeDiscipline: string; //,
    codeRA: string; // required
}

export class place{
    annee_pedagogique: string;
    nombre_place: number;
    constructor(){
        this.annee_pedagogique = "";
        this.nombre_place = 0;
    }
}

export class serie{
    libelle: string;
}

export class diplome{
    libelle: string;
}

// Some fields can be null, due to the fact that this class has an old version where these fields were nonexistent
export class StructureRecherche {
  id:            number;
  libelle:       string;
  libelleAr?:     string;
  nature:        NatureStructureRecherche;
  cedoc?:         Cedoc;
  responsable:   Enseignant;
  etablissement?: Etablissement;
  universite?:    Universite;
  universitaire: boolean;
}

export class Cedoc {
  id:             number;
  libelle:        string;
  libelleAr?:      string;
  directeur?:     Enseignant;
  universiteDomiciliante?: Universite
  etablissementDomiciliant?: Etablissement;
  etablissementsMembres?: Etablissement[];
  membresConseilCedoc:MembreConseilCedoc[];
  structuresRecherche: StructureRecherche[];
  domaines:Domaine[];
  champsDisciplines:ChampDiscipline[];
  thematiquesRecherche: ThematiqueRecherche[];
  formationsCommunes: string;
  dispositionsInsertionProfessionnelles: string;
  dispositionsSuiviInsertionProfessionnelles: string;
  allocationsBourses: string;
}

export interface MembreConseilCedoc {
  id?: number,
  fullName: string,
  etablissement: string,
  qualite: string,
  affiliation: Affiliation
}

export class NatureStructureRecherche {
  id: number;
  libelle: string;
}

export class ThematiqueRecherche {
  id?:number;
  description:string
}

export enum Affiliation {
  ETUDIANTS = 'ETUDIANTS',
  EXTERIEURS = 'EXTERIEURS',
  UNIVERSITAIRES = 'UNIVERSITAIRES',
  INTERNES = 'INTERNES'
}

export class ChampDiscipline {
  id: number;
  libelle:string;
}


enum type_piece{
	DescriptifWord, DescriptifPdf, ChartEtudiant, CVCoordonateur, ContratCoordonateur, DiplomeCoordonateur, EngagementCoordonateur, RecuPaiement
}
enum AVIS{
	Favorable, Defavorable
}
enum type_etablissement {
	PUBLIC, PRIVE
}
enum Statut_Coordonateur{
    PERMANENT, VACCATAIRE
}
enum Langue {
	FRANCAIS, ANGLAIS, ESPAGNOLE, ALLEMAND, ARABE, CHINOIS
}
enum Etape {
	INFO, COORDONATEUR, VALIDATION, ATTACHEMENT
}
enum STG {
	EnCours, ComplementRecue, ComplementEnvoye
}
enum AGE {
	MettreAjourDemande
}

enum STRA{
	NouvelleDemandeRecue, Affecte, DemandeComplementRecu, DemandeComplementTraite, EnCoursAneaq
}

enum ARA{
	AAffecter, TraiterDemandeComplementRT
}

enum STRT{
	NouvelleDemandeRecue, Traite, DemandeComplementValide, ComplementRecue
}

enum ART{
	ATraiter
}
enum ResultatTR{
	EnAttente, Approuver, Refuser
}

export enum CodeDiplome {
  LICENCE_EDUCATION = 'D0025',
  LICENCE_PROFESSIONNELLE = 'D0014',
  MASTER = 'D0003',
  MASTER_SPECIALISE = 'D0004',
  DOCTORAT = 'D0002',
  DIPLOME_UNIVERSITAIRE_TECHNOLOGIE = 'D0015',
  MASTER_SCIENCES_TECHNIQUES = 'D0005',
  LICENCE_PROFESSIONNELLE_S5_S6 = 'D0200',
  NOUVELLE_LICENCE='D0071',
  BAC_PLUS_3='D0072',
  BAC_PLUS_3_S5_S6='D0073',
  LICENCE_EXCELLENCE = 'D0074',
  METIERS_ART_DESIGN = 'D0037',
  'DIPLOME_TRADUCTEUR' = 'D0011',
  'LICENCE_SCIENCES_TECHNIQUES' = 'D0013',
  'DIPLOME_ECOLES_NATIONALES_COMMERCE_GESTION' = 'D0016',
  'DIPLOME_INGENIEUR_ETAT' = 'D0017',
  'DIPLOME_INTERPRETE' = 'D0027',
  'DIPLOME_ECOLE_NATIONALE_SUPERIEURE_ART_DESIGN' = 'D0038',
  'ANNEES_PREPARATOIRES' = 'D0099',
    NEW_MASTER_PUBLIC = 'D0076',
    NEW_MASTER_PRIVE = 'D0077',
    MASTER_EXCELLENCE = 'D0078'
}

export interface ActiviteParallele {
  id: number | null,
  modaliteEvaluation: string,
  competenceAquerire: string,
  nature: string,
  modaliteComptabilisation: string,
  duree: string,
  demandeFiliere: {
    id: number
  }
}

export enum NatureModule{
    LANGUES_ETRANGERES = 27,
    POWER_SKILLS = 35
}
