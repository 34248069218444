import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routes } from './app.routing';
import { SharedModule } from './modules/shared/shared.module';
import { AuthService } from './services/auth.service';
import { DescriptifGeneratorService } from './services/descriptifGenerator.service';
import { GlobalService } from './services/global.service';
import { AuthGuard, CanExitGuard, HomeGuard, LoginGuard, RoleGuard } from './services/guards.service';
import { HelperService } from './services/helper.service';
import { HttpService } from './services/http.service';
import { HttpInterceptorToken } from './services/interceptor.service';
import { RapportsExpertiseGeneratorService } from './services/rapportsExpertiseGenerator.service';
import { UtilityService } from './services/utility.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ToastrModule.forRoot(),
    ChartsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorToken,
      multi: true
    },
    HttpService,
    HelperService,
    AuthService,
    DescriptifGeneratorService,
    RapportsExpertiseGeneratorService,
    LoginGuard,
    HomeGuard,
    RoleGuard,
    AuthGuard,
    CanExitGuard,
    GlobalService,
    UtilityService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
