import { Injectable } from "@angular/core";
import { HttpService } from 'src/app/services/http.service';
import { createReport } from 'docx-templates';
import { HelperService } from './helper.service';

@Injectable()
export class DocxGeneratorService {

    constructor(private http: HttpService, private helper: HelperService,
    ) { }


    async createDocxReportAsBlob(data, docxTemplate): Promise<Blob> {
        //Load template
        const file = await this.http.downloadBinary(docxTemplate).then((res) => { return res });
        const template: any = await this.readFileIntoArrayBuffer(file);
        const report = await createReport({
            template,
            noSandbox: true,
            data: data,
            errorHandler: (err, command_code) => {
                console.log(err);
                console.log(command_code);
               // this.helper.alert("Erreur", "Une erreur est survenue", "error");
                return null;
            },
        });

        //Init blob object
        const blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        return blob;
    }

    async readFileIntoArrayBuffer(fd) {
        const reader = new FileReader();
        return await new Promise(async (resolve, reject) => {
            reader.onerror = reject;
            reader.readAsArrayBuffer(fd);
            reader.onload = () => {
                resolve(reader.result);
            }
        });
    }

    saveDataToFile(blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        this.http.downloadURL(url, fileName);
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    }
}