import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DisableDirective } from 'src/app/directives/DisableDirective';
import { DropzoneDirective } from 'src/app/directives/dropzone';
import { DocxGeneratorService } from 'src/app/services/docxGeneratorService';
import { TranslatorService } from 'src/app/services/translator.service';
import { SafePipe } from './../../pipes/safe.pipe';
import { DrawerComponent } from './drawer/drawer.component';
import { FooterComponent } from './footer/footer.component';
import { GeneratePdfDescriptionComponent } from './generate-pdf-description/generate-pdf-description.component';
import { GenerateWordComponent } from './generate-word/generate-word.component';
import { HeaderComponent } from './header/header.component';
import { InfosEnseignantComponent } from './infos-enseignant/infos-enseignant.component';
import { CoordonnateurComponent } from './modals/coordonnateur/coordonnateur.component';
import { EnseignantComponent } from './modals/enseignant/enseignant.component';
import { ModalAncienneFiliereComponent } from './modals/modal-ancienne-filiere/modal-ancienne-filiere.component';
import { ModalMotifRefusComponent } from './modals/modal-motif-refus/modal-motif-refus.component';
import { EnseignantLookupComponent } from './enseignant-lookup/enseignant-lookup.component';
export function translateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    EnseignantComponent,
    HeaderComponent,
    FooterComponent,
    DrawerComponent,
    CoordonnateurComponent,
    GenerateWordComponent,
    GeneratePdfDescriptionComponent,
    InfosEnseignantComponent,
    ModalMotifRefusComponent,
    SafePipe,
    DropzoneDirective,
    DisableDirective,
    ModalAncienneFiliereComponent,
    EnseignantLookupComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatExpansionModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    RouterModule,
    MatMenuModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule,
    NgbModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader),
        deps: [HttpClient]
      }
    }),
    MatButtonModule
  ],
  providers: [
    TranslatorService,
    DocxGeneratorService
  ],
  entryComponents: [
    EnseignantComponent,
    CoordonnateurComponent,
    ModalMotifRefusComponent,
    ModalAncienneFiliereComponent
  ],
  exports: [
    EnseignantComponent,
    CoordonnateurComponent,
    HeaderComponent,
    FooterComponent,
    DrawerComponent,
    GenerateWordComponent,
    GeneratePdfDescriptionComponent,
    InfosEnseignantComponent,
    ModalMotifRefusComponent,
    SafePipe,
    DropzoneDirective,
    DisableDirective,
    ModalAncienneFiliereComponent,
    EnseignantLookupComponent
  ]
})
export class SharedModule { }
