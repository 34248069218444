import { Injectable } from "@angular/core";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { DocxGeneratorService } from './docxGeneratorService';
import { HelperService } from './helper.service';
import { HttpService } from './http.service';
import {CodeDiplome} from "../app.entities";


@Injectable()
export class DescriptifGeneratorService {

    //Init ref
    private docxGenerator = null;
    private pdfGenerator = null;

    base_url = environment.base_url;
    download_url = this.base_url + 'downloadFile/';

    constructor(private helper: HelperService, private http: HttpService, public docxGeneratorService: DocxGeneratorService) { }

    /**
     * Summary. Set docxGenerator ref
     * @param ref
     */
    setDocxGenerator(ref) {
        this.docxGenerator = ref;
    }

    /**
     * Summary. Get docxGenerator
     */
    getDocxGenerator() {
        return this.docxGenerator;
    }

   isNouvelleLicence(demande): boolean {
    return [CodeDiplome.NOUVELLE_LICENCE, CodeDiplome.BAC_PLUS_3, CodeDiplome.LICENCE_EXCELLENCE].includes(demande.diplomeObj.code)
  }
   isNewMasterPublic(demande): boolean {
    return [CodeDiplome.NEW_MASTER_PUBLIC, CodeDiplome.MASTER_EXCELLENCE].includes(demande.diplomeObj.code)
  }
   isNewMasterPrive(demande): boolean {
    return [CodeDiplome.NEW_MASTER_PRIVE].includes(demande.diplomeObj.code)
  }

    /**
 *
 * @param demande Sumary. Generate descriptif in Zip file format
 */
    async generateZipFile(demande) {
        //Init loading
        this.helper.showLoading();

        //Init attachements
        let attachements: any = null;

        await this.http.get("demande-filieres/attachements/" + demande.id).then((result: any) => { attachements = result });

        //Get request
      await this.http.get("modules-filiere/" + demande.id).then((result: any) => {
        this.docxGenerator.setRequest({
          ...result.demandeFiliere,
          activiteParallele: result.activiteParallele
        })
      });

        //Check if attachements is not empty
        if (attachements) {
            //Init JZIP
            const jszip = new JSZip();

            //Init folders
            let rootFolder = jszip.folder("download");
            let coordonateurFolder = rootFolder.folder("Coordonnateur");
            // let conventions = rootFolder.folder("Conventions_Partenariat");
            let enseignants = rootFolder.folder("Enseignants_Permanents");
            let factures = rootFolder.folder("Justification_Materiel_Equipement");

            //Check if diplome != doctorat
            if (!demande.diplome.toLowerCase().includes('doctorat')) {
                let template = 'assets/docs/Descriptif-Filiere-' + (demande.langue == 'ARABE' ? 'Arabe' : 'Francais') + '.docx'

                if (this.isNouvelleLicence(demande)) {
                    template = 'assets/docs/Desciptif_Licence_2023_' + (demande.langue == 'ARABE' ? 'Ar' : 'Fr') + '.docx'
                } else if (this.isNewMasterPublic(demande)) {
                    template = 'assets/docs/new-master/Descriptif_Master_2024_' + (demande.langue == 'ARABE' ? 'Ar' : 'Fr') + '.docx'
                } else if (this.isNewMasterPrive(demande)) {
                    template = 'assets/docs/new-master/Descriptif_Master_Prive_Fr_2024.docx'
                }
                //Init DOCX descriptif generation
                const data = this.docxGenerator.initDescriptifData()
                let descriptifDocx = await this.docxGeneratorService.createDocxReportAsBlob(data, template);
                rootFolder.file("Descriptif_Filiere_" + demande.titre + ".docx", descriptifDocx, {binary: true});
            }

            //Parse through pieceJoints => Cant you foreach loop with an await clause
            for (let index = 0; index < attachements.pieceJoints.length; index++) {
                //Init doc
                const doc = attachements.pieceJoints[index];

                //Check if doc's url is not null
                if (!doc.url) continue;

                //Check if diplome = doctorat
                if (demande.diplome.toLowerCase().includes('doctorat')) {
                    //Download FR & AR filiere descriptif
                    if (doc.type == "DescriptifPdf") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                        //Save descriptif
                        rootFolder.file("Descriptif_Filiere_" + demande.titre + ".pdf", data, { binary: true });
                    });
                    else if (doc.type == "DescriptifWord") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                        //Save descriptif
                        rootFolder.file("Descriptif_Filiere_" + demande.titre + ".docx", data, { binary: true });
                    });
                }
                if (doc.type == "RecuPaiement") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Reçu_Paiement.pdf", data, { binary: true });
                });
                if (doc.type == "AvisPaiement") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Avis_Paiement.pdf", data, { binary: true });
                });
                else if (doc.type == "rapportAutoEvaluation") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Rapport_Auto_Evaluation.pdf", data, { binary: true });
                });
                else if (doc.type == "ChartEtudiant") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Chart_Etudiant.pdf", data, { binary: true });
                });
                else if (doc.type == "ReglementInterneCEDoc") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Reglement_Interne_CEDoc.pdf", data, { binary: true });
                });
                else if (doc.type == "CVCoordonateur") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    coordonateurFolder.file("CV_Coordonnateur_" + demande.titre + ".pdf", data, { binary: true });
                });
                else if (doc.type == "ContratCoordonateur") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    coordonateurFolder.file("Contrat_Coordonnateur_" + demande.titre + ".pdf", data, { binary: true });
                });
                else if (doc.type == "DiplomeCoordonateur") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    coordonateurFolder.file("Diplome_Coordonnateur_" + demande.titre + ".pdf", data, { binary: true });
                });
                else if (doc.type == "EngagementCoordonateur") await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    coordonateurFolder.file("Engagement_Coordonnateur_" + demande.titre + ".pdf", data, { binary: true });
                });
            };

            //Parse through conventions
            /*  for (let index = 0; index < attachements.conventions.length; index++) {
                  //Init doc
                  const doc = attachements.conventions[index];

                  //Check if doc's url is not null
                  if (!doc.url) continue;

                  //Download convention
                  await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                      //Save descriptif
                      conventions.file("Convention_" + doc.libelle, data, { binary: true });
                  });
              };
  */
            //Parse through factures
            for (let index = 0; index < attachements.factures.length; index++) {
                //Init doc
                const doc = attachements.factures[index];

                //Check if doc's url is not null
                if (!doc.url) continue;

                //Download facture
                await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    factures.file("Facture_" + doc.libelle, data, { binary: true });
                });
            };

            //Parse through pieceJoints
            for (let index = 0; index < attachements.enseignantPieceJoints.length; index++) {
                //Init doc
                const doc = attachements.enseignantPieceJoints[index];

                //Check if doc's url is not null
                if (!doc.url) continue;

                //Download enseignant
                await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    enseignants.file("Enseignant_" + doc.enseignant.nom + "_" + doc.enseignant.prenom + "_" + doc.type + "_" + doc.libelle, data, { binary: true });
                });
            };

            //Parse through visas
            for (let index = 0; index < attachements.visas.length; index++) {
                //Init doc
                const doc = attachements.visas[index];

                //Check if doc's url is not null
                if (!doc.url) continue;

                //Download visa
                await this.http.downloadBinary(this.download_url + doc.url).then((data: any) => {
                    //Save descriptif
                    rootFolder.file("Visa_" + doc.libelle, data, { binary: true });
                });
            };

            //Assemble folders
            rootFolder.generateAsync({ type: "blob" }).then(function (content) {
                // see FileSaver.js
                saveAs(content, demande.titre + ".zip");
            });
        }

        //End
        this.helper.hideLoading();
    }
}
