import {DatePipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {HelperService} from 'src/app/services/helper.service';
import {UtilityService} from 'src/app/services/utility.service';
import {DescriptifGeneratorService} from 'src/app/services/descriptifGenerator.service';
import {DocxGeneratorService} from 'src/app/services/docxGeneratorService';
import {CodeDiplome} from "../../../app.entities";

@Component({
  selector: 'app-generate-word',
  templateUrl: './generate-word.component.html',
  styleUrls: ['./generate-word.component.scss']
})
export class GenerateWordComponent implements OnInit {
  request_mod: any = {};
  _dem_fil_all: any;
  stages: any = {};
  validations: any;
  descriptif_libelle: String;
  semestres: any = {};
  departements = [];

  @Input() request: any = {};
  @Input() dateImportante: any = {};

  constructor(
    private _helper: HelperService,
    public router: Router,
    public _auth: AuthService,
    public datepipe: DatePipe,
    public utilityService: UtilityService,
    public descriptifGeneratorService: DescriptifGeneratorService,
    private docxGenerator: DocxGeneratorService
  ) {
    //Init webrtc service
    this.descriptifGeneratorService.setDocxGenerator(this);
  }

  ngOnInit() { }

  /**
   * Summary. Set request from external source
   * @param request
   * @param dateImportante
   */
  setRequest(request, dateImportante = null) {
    this.request = request;
    this.dateImportante = dateImportante;
  }

  /**
   * Summary. Generate departements intervenants
   */
  initDepartements() {
    if (this.request.modules.length > 0) {
      this.request.modules.forEach(element => {
        if(element.departement) this.departements.push(element.departement)
      });
      this.departements = this.getUniqueListBy(this.departements, 'libelle');
    }
  }

  /**
   * Summary. Clean up duplicates
   * @param arr
   * @param key
   */
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  /**
   * Summary. Request modules
   */
  // requestModules() {
  //   this._http.get("modules-filiere/" + this.request_id).then((result: any) => {
  //     this.request = result.demandeFiliere;
  //     //Ordre modules by numero Ordre
  //     // let modules = this.sortModules(this.request.modules, 'numeroOrdre');

  //     //Sort modules by semestres
  //     // let semestres = this.sortBySemestre(modules, this.request.stages);
  //     // this.sortIntervenants(modules);
  //   });
  // }




  /**
   * Summary. Compute pourcentage
   * @param numb
   * @param total
   */
  percentage(numb, total) {
    if (Number.isNaN(numb))
      numb = 0;
    if (Number.isNaN(total) || total == 0) {
      return 0;
    }
    else
      return (numb / total * 100).toFixed(0);
  }

  /**
   * Summary.Load the user-provided file into an ArrayBuffer
   * @param fd
   */
  async readFileIntoArrayBuffer(fd) {
    const reader = new FileReader();
    return await new Promise(async (resolve, reject) => {
      reader.onerror = reject;
      reader.readAsArrayBuffer(fd);
      reader.onload = () => {
        resolve(reader.result);
      }
    });
  }

  /**
   * Summary. Init semestres length
   */
   _getDureeIndexes(): any {
    if (["D0200", CodeDiplome.BAC_PLUS_3_S5_S6, CodeDiplome.LICENCE_EXCELLENCE].includes(this.request.diplome.code)) {
      return {
        startIndex: 5,
        endIndex: 6
      }
    } else {
      return {
        startIndex: 1,
        endIndex: (this.request.diplome.duree * 2)
      }
    }
  }

  /**
 * Summary. Push module to blocs list
 * @param blocs Blocs full liste
 * @param module Module related
 */
  pushModuleToBlocs(blocs, module) {
    //Init flag
    let found = false;

    // Parse through blocs
    blocs.forEach(bloc => {
      if (module.natureModule && bloc.natureModule && bloc.natureModule.id == module.natureModule.id) {
        found = true;
        bloc.modules.push(module);
        bloc.vhGlobal += module.vhGlobal;
      }
    });

    //Check flag result
    if (!found) {
      //Init bloc modules
      let bloc = {
        natureModule: module.natureModule,
        modules: [module],
        vhGlobal: module.vhGlobal
      };
      blocs.push(bloc);
    }
  }

  /**
   * Summary. Sort by nature module
   * @param modules Modules list
   */
  sortByNatureModule(modules) {
    //Init blocs list
    let blocs = [];

    //Parse through modules
    modules.forEach(module => {
      this.pushModuleToBlocs(blocs, module);

      //Temporarly fix weird docx issue, module.noteModule causes exception in fr & ar descriptif
      module.volumeHoraire.noteModule = module.noteModule;
      module.coordonateur = this.request.langue==="ARABE"
        ?module.coordonnateurModule.nomAr+" "+module.coordonnateurModule.prenomAr
        :module.coordonnateurModule.nom+" "+module.coordonnateurModule.prenom
    });

    return blocs;
  }

  /**
 * Summary. Push module to options list
 * @param blocs Blocs full liste
 * @param module Module related
 */
  pushModuleToOptions(options, module) {
    //Parse through module's options
    module.options.forEach(opt => {
      //Init flag
      let found = false;

      // Parse through main options list
      options.forEach(option => {
        if (opt.id == option.parcours.id) {
          found = true;
          option.modules.push(module);
          option.vhGlobal += module.vhGlobal;
        }
      });

      //Check flag result
      if (!found) {
        //Init bloc modules
        let option = {
          parcours: opt,
          modules: [module],
          vhGlobal: module.vhGlobal
        };
        options.push(option);
      }
    });
  }

  /**
   * Summary. Sort modules by option
   * @param modules Modules list
   */
  sortByOptions(modules) {
    //Init options list
    let options = [];

    //Parse through modules
    modules.forEach(module => {
      this.pushModuleToOptions(options, module);
    });

    return options;
  }

  /**
   * Summary. Sort modules by semestre
   * @param modules Modules list
   * @param stages Stages list
   */
  sortBySemestre(modules, stages) {
    //Get semestres length
    const dureeIndexes = this._getDureeIndexes();

    //Init semestres array
    let semestres = [];

    //Parse through semestres
    if (modules && modules.length > 0) {
      for (let a = dureeIndexes.startIndex; a <= dureeIndexes.endIndex; a++) {
        //Filter by numeroSemestre
        let filtredModules = modules.filter(module => module.numeroSemestre == a);
        let filtredStages = stages.filter(stage => stage.numeroSemestre == a);

        // Filter by options
        let filtredOptions = this.sortByOptions(filtredModules);

        //Clear modules that have an option/parcours
        // filtredModules = filtredModules.filter(module => (!module.options || module.options.length ==0));

        // Push to semestres
        semestres.push({
          index: a,
          vhGlobal: this.getVHGlobaleSemestre(filtredModules),
          totalCredits: this.getCreditsTotal(filtredModules),
          modules: filtredModules,
          options: filtredOptions,
          stages: filtredStages
        });
      }
    }

    return semestres;
  }

  getCreditsTotal(modules) {
    let totalCredits = 0;
    modules.forEach(m => {
      totalCredits += m.nombreEcts
    })
    return totalCredits
  }

  /**
   * Summary. Format vh values
   * @param num
   */
  getValue(num: any): number {
    if ((num != null) && (num !== '') && !isNaN(Number(num.toString()))) {
      return num;
    }
    return 0;
  }

  /**
   * Summary. Get vh global for composants
   * @param composant
   */
  getVHGlobaleComposant(composant): number {
    let vhCours = this.getValue(composant.vhCours);
    let vhTp = this.getValue(composant.vhTp);
    let vhTd = this.getValue(composant.vhTd);
    let vhTravailPersonnel = this.getValue(composant.vhTravailPersonnel);
    let vhEvaluationConnaissances = this.getValue(composant.vhEvaluationConnaissances);
    let vhActivitesPratiques = this.getValue(composant.vhActivitesPratiques);
    return vhCours + vhTp + vhTd + vhTravailPersonnel + vhEvaluationConnaissances + vhActivitesPratiques;
  }

  /**
   * Summary. Get Semestre VH global
   * @param modules
   */
  getVHGlobaleSemestre(modules) {
    //Init vh global
    let vhGlobal = 0;

    //Parse through modules
    modules.forEach(module => {
      if (module.volumeHoraire && module.volumeHoraire.composantsVolumeHoraire) {
        //Init vhGlobal for module
        module.vhGlobal = 0;
        module.vhCoursGlobal = 0;
        module.vhTdGlobal = 0;
        module.vhTpGlobal = 0;
        module.vhTravailPersonnelGlobal = 0;
        module.vhEvaluationConnaissancesGlobal = 0;
        module.vhActivitesPratiquessGlobal = 0;

        //Parse through module's composants
        module.volumeHoraire.composantsVolumeHoraire.forEach(composant => {
          // Inject composant vh statistiques
          composant.vhGlobal = this.getVHGlobaleComposant(composant);

          // Inject VH statistiques to module's vhGlobal
          module.vhCoursGlobal += this.getValue(composant.vhCours);
          module.vhTdGlobal += this.getValue(composant.vhTd);
          module.vhTpGlobal += this.getValue(composant.vhTp);
          module.vhTravailPersonnelGlobal += this.getValue(composant.vhTravailPersonnel);
          module.vhEvaluationConnaissancesGlobal += this.getValue(composant.vhEvaluationConnaissances);
          module.vhActivitesPratiquessGlobal += this.getValue(composant.vhActivitesPratiques);

          module.vhGlobal += composant.vhGlobal;
        });

        //Add module's vhGlobal
        vhGlobal += module.vhGlobal;
      }
    });

    return vhGlobal;
  }


  /**
   * Summary. Push intervenant to intervenants list
   * @param intervenants Intervenants full liste
   * @param intervenant Intervenant to be pushed
   * @param nature Intervenant nature
   * @param module Module related
   */

  getIntervenantVHByModule(intervenant, mod) {
    let modules = this.request.modules;
    let vhCours = 0, vhTd = 0, vhTp = 0, vhEncadrementProjet = 0, vhEncadrementStage = 0;

    let nbrIntervenantCours = 0, nbrIntervenantTd = 0, nbrIntervenantTp = 0, nbrIntervenantEncadrementProjet = 0, nbrIntervenantEncadrementStage = 0;

    let module = modules.find((module: any) => module.id === mod.id);

    if (this.utilityService.getBoolean(module.natureIntervention.cours)) nbrIntervenantCours++;
    if (this.utilityService.getBoolean(module.natureIntervention.td)) nbrIntervenantTd++;
    if (this.utilityService.getBoolean(module.natureIntervention.tp)) nbrIntervenantTp++;
    if (this.utilityService.getBoolean(module.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
    if (this.utilityService.getBoolean(module.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;

    for (let inter of module.intervenants) {
      if (this.utilityService.getBoolean(inter.natureIntervention.cours)) nbrIntervenantCours++;
      if (this.utilityService.getBoolean(inter.natureIntervention.td)) nbrIntervenantTd++;
      if (this.utilityService.getBoolean(inter.natureIntervention.tp)) nbrIntervenantTp++;
      if (this.utilityService.getBoolean(inter.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
      if (this.utilityService.getBoolean(inter.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;
    }

    if (this.utilityService.getBoolean(intervenant.natureIntervention.cours))
      vhCours += this.getTotalVHCours(module) / nbrIntervenantCours;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.td))
      vhTd += this.getTotalVHTd(module) / nbrIntervenantTd;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.tp))
      vhTp += this.getTotalVHTp(module) / nbrIntervenantTp;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementProjet))
      vhEncadrementProjet += this.getTotalVHEvaluationConnaissances(module) / nbrIntervenantEncadrementProjet;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementStage))
      vhEncadrementStage += this.getTotalVHTravailPersonnel(module) / nbrIntervenantEncadrementProjet;

    let total = vhCours + vhTd + vhTp + vhEncadrementProjet + vhEncadrementStage;
    return total.toFixed(1) + ' H';
  }
  getTotalVHCours(module: any): number {
    let totalVHCours = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalVHCours += element.vhCours;
    }
    return totalVHCours;
  }

  getPercentageVHCours(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHCours = this.getTotalVHCours(module);
    const percentage = (totalVHCours * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTd(module: any): number {
    let totalvhTd = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTd += element.vhTd;
    }
    return totalvhTd;
  }

  getPercentageVHTd(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHTd = this.getTotalVHTd(module);
    const percentage = (totalVHTd * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTp(module: any): number {
    let totalvhTp = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTp += element.vhTp;
    }
    return totalvhTp;
  }

  getPercentageVHTp(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHTp = this.getTotalVHTp(module);
    const percentage = (totalVHTp * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTravailPersonnel(module: any): number {
    let totalvhTravailPersonnel = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTravailPersonnel += element.vhTravailPersonnel;
    }
    return totalvhTravailPersonnel;
  }

  getTotalVHEvaluationConnaissances(module: any): number {
    let totalvhEvaluationConnaissances = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhEvaluationConnaissances += element.vhEvaluationConnaissances;
    }
    return totalvhEvaluationConnaissances;
  }

  getVHGlobaleModule(volumeHoraire: any): number {
    let vhGlobaleModule = 0;
    if (volumeHoraire) {
      const composantsVolumeHoraire = volumeHoraire.composantsVolumeHoraire;
      for (let composant of composantsVolumeHoraire) {
        const vhGlobalComposant = this.getVHGlobaleComposant(composant);
        vhGlobaleModule += vhGlobalComposant;
      }
    }
    return vhGlobaleModule;
  }
  pushIntervenant(intervenants, intervenant, nature, module) {
    //Init flag
    let found = false;
    let natureIntervantion: any = {};
    let intervenantVHByModule: any = {};
    if (intervenant.id) {
      natureIntervantion = module.intervenants.find(elem => elem.id = intervenant.id).natureIntervention;
    } else if (module.coordonnateurModule.id == intervenant.enseignant.id) {
      natureIntervantion = module.natureIntervention;
    }
    intervenantVHByModule = this.getIntervenantVHByModule(intervenant, module);
    // Parse through intervenants
    intervenants[nature].forEach(elem => {
      if (elem.enseignant.id == intervenant.enseignant.id) {
        found = true;
        elem.modules.push({id: module.id, intitule: module.intitule, natureIntervantion: natureIntervantion, intervenantVHByModule: intervenantVHByModule});
      }
    });

    //Check flag result
    if (!found) {
      //Init intervenant modules
      intervenant.modules = [{id: module.id, intitule: module.intitule, natureIntervantion: natureIntervantion, intervenantVHByModule: intervenantVHByModule}];
      intervenants[nature].push(intervenant);
    }
  }

  /**
   * Summary. Sort intervenants
   * @param modules
   */
  sortIntervenants(modules) {
    //Init intervenants type
    let intervenants = {
      etablissements: [],
      universites: [],
      externes: [],
      consultants: [],
    };

    //Parse through
    for(let module of modules) {
      // Push coordonnateurModule
      if (module.coordonnateurModule) {
        //Init intervenant
        let intervenant = {
          enseignant: module.coordonnateurModule,
          natureIntervention: module.natureIntervention
        }

        //Check nature
        if (!module.coordonnateurModule.etablissement && module.coordonnateurModule.organisme) this.pushIntervenant(intervenants, intervenant, 'consultants', module);
        else if (module.coordonnateurModule.etablissement && module.coordonnateurModule.etablissement.id == this.request.etablissement.id) this.pushIntervenant(intervenants, intervenant, 'etablissements', module);
        else if (module.coordonnateurModule.etablissement && module.coordonnateurModule.etablissement.universite.id == this.request.etablissement.universite.id) this.pushIntervenant(intervenants, intervenant, 'universites', module);
        else this.pushIntervenant(intervenants, intervenant, 'externes', module);
      }

      //Parse through intervenants
      for(let intervenant of module.intervenants) {
        //Check nature
        if (!intervenant.enseignant.etablissement && intervenant.enseignant.organisme) this.pushIntervenant(intervenants, intervenant, 'consultants', module);
        else if (intervenant.enseignant.etablissement && intervenant.enseignant.etablissement.id == this.request.etablissement.id) this.pushIntervenant(intervenants, intervenant, 'etablissements', module);
        else if (intervenant.enseignant.etablissement && intervenant.enseignant.etablissement.universite.id == this.request.etablissement.universite.id) this.pushIntervenant(intervenants, intervenant, 'universites', module);
        else if (intervenant.enseignant.etablissement) this.pushIntervenant(intervenants, intervenant, 'externes', module);
      }
    }

    return intervenants;
  }

  /**
   * Summary. Init descriptif data
   */
  uniqueSpecialite = [];

  // check if element exust in array
  doesElementExist(specialite: string): boolean {
    return this.uniqueSpecialite.some(element => element.specialite === specialite);
  }
  
  initDescriptifData(): any {

    // Init unique Specialite diplome Requis
      this.request.diplomeRequis.forEach((item) => {
        if (!this.doesElementExist(item.specialite)) {
          this.uniqueSpecialite.push(item);
        }
      });

    //Init departements
    this.initDepartements();

    //Ordre modules by numero Ordre
    let modules = this.request.modules

    this.updateModulesNatureIntervention(modules);

    //Sort modules by semestres
    let semestres = this.sortBySemestre(modules, this.request.stages);

    //Sort intervenants
    let intervenants = this.sortIntervenants(modules);

    this.updateIntervenantsNatureIntervention(intervenants);

    //Sort blocs
    let blocs = this.sortByNatureModule(modules);

    //Compute total VH
    let totalVh = 0;
    blocs.forEach(bloc => {
      totalVh += bloc.vhGlobal;
    });

    //Check if coordonnateur etablissement is null
    if(!this.request.enseignant.etablissement) this.request.enseignant.etablissement = {libelle: '', libelleAr: ''};
    //Check if autreRenseignements is null
    if(!this.request.autreRenseignements) this.request.autreRenseignements = '';
    // console.log("### d.enseignant.departement.libelle : ", this.request.enseignant.departement.libelle);
    console.log("### request : ", this.request);
    const tc_ = this.getTroncCommun()
    console.log("Tronc commun", tc_)
    //Init report data
    let data = {
      activiteParallele: this.request.activiteParallele,
      d: this.request,
      tc:tc_,
      dateImportante: this.dateImportante ? this.dateImportante.endDate : '',
      year: this.request.session.annee,
      dateInitiation: this.datepipe.transform(this.request.dateInitiation, 'dd/MM/yyyy'),
      diplome: this.request.langue === 'ARABE' ? this.request.diplome.libelleAr.toUpperCase() : this.request.diplome.libelle.toUpperCase(),
      ancieneFiliere: this.request.ancieneFiliere,
      universite: this.request.langue === 'ARABE' ? this.request.etablissement.universite.libelleAr : this.request.etablissement.universite.libelle,
      etablissement: this.request.langue === 'ARABE' ? this.request.etablissement.libelleAr : this.request.etablissement.libelle,
      hasDepartements: !['D0009', 'D0012', 'D0008'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie
      departements: this.departements,
      hasOptions: !['D1003', 'D0099', 'D0008', 'D0009'].includes(this.request.diplome.code), //Hide for AP, Medicine, Dentaire
      options: this.request.options,
      langue: this.request.langue,
      intituleAutre: this.request.intituleAutre,
      intituleAr: this.request.intituleAr,
      intituleFr: this.request.intituleFr,
      intituleEn: this.request.intituleEn,
      coordonnateurEtablissement: this.request.langue == 'ARABE' ? this.request.enseignant.etablissement.libelleAr : this.request.enseignant.etablissement.libelle,
      coordonnateurDepartement: this.request.enseignant.departement ? (this.request.langue == 'ARABE' ? this.request.enseignant.departement.libelleAr : this.request.enseignant.departement.libelle) : this.request.enseignant.autreDepartement,
      coordonnateurNom: this.request.langue == 'ARABE' ? (this.request.enseignant.prenomAr + ' ' + this.request.enseignant.nomAr) : (this.request.enseignant.prenom + ' ' + this.request.enseignant.nom),
      coordonnateurGrade: this.request.enseignant.grade ? (this.request.langue == 'ARABE' ? this.request.enseignant.grade.libelleAr : this.request.enseignant.grade.libelle) : this.request.enseignant.autreGrade,
      coordonnateurDiplome: this.request.enseignant.diplome ? (this.request.langue == 'ARABE' ? this.request.enseignant.diplome.libelleAr : this.request.enseignant.diplome.libelle) : "",
      hasChefDepartement: !['D0009', 'D0012', 'D0008'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie
      avisChefsDepartement: this.request.avisChefsDepartement ? true : false,
      avisCoordonnateur: this.request.avisCoordonnateur ? true : false,
      hasOtherDepartementsDI: ['D0017'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie
      validationConseilEtablissement: this.request.validationConseilEtablissement ? true : false,
      validationConseilUniversite: this.request.validationConseilUniversite ? true : false,
      modules: modules,
      blocs: blocs,
      totalVh: totalVh,
      totalCredits: semestres.reduce((acc, curr) => acc + curr.totalCredits, 0),
      stages: this.request.stages,
      discipline: this.request.langue === 'ARABE' ? this.request.discipline.libelleAr : this.request.discipline.libelle,
      keywords: this.request.keywords,
      objectifFormation: this.request.objectifFormation,
      hasDiplomeRequis: !['D0008'].includes(this.request.diplome.code), //Hide for Medicine
      hasPreRequis: !['D0009', 'D0012', 'D0008', 'D0016'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie & ENCG
      hasProcedureSelection: !['D0009', 'D0012', 'D0008', 'D0016'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie & ENCG
      hasPasserelle: !['D0003', 'D0004', 'D0005'].includes(this.request.diplome.code), //Hide for Dentaire, Pharmacie
      hasEffectivePrevue: !['D0009', 'D0012', 'D0008', 'D0016'].includes(this.request.diplome.code), //Hide for Medicine, Dentaire, Pharmacie & ENCG
      hasArticulation: true, // ['D0017'].includes(this.request.diplome.code) Hide except for DI
      hasOrganisationModulaire: !['D0009', 'D0012', 'D0008'].includes(this.request.diplome.code), //Hide except for medicine, pharmaci, ENCG, DI, ART...
      semestres: semestres,
      intervenants: intervenants,
      hasThese: ['D0008', 'D0009'].includes(this.request.diplome.code), //Hide except for medicine & dentaire
      hasBlocModules: ['D1003', 'D0099', 'D0017', 'D0016', 'D0008', 'D0009', 'D0012', 'D0037', 'D0038'].includes(this.request.diplome.code), //Hide except for AP & DI & ENCG & pharmacie & medicine & dentaire
      hasDescriptionStages: ['D1003', 'D0099', 'D0017', 'D0008', 'D0012'].includes(this.request.diplome.code), //Hide except AP & DI & Medicine & Pharma
      hasValidationAnnee: ['D1003', 'D0099', 'D0017'].includes(this.request.diplome.code), //Hide except AP & DI
      hasValidation5emeSemestre: ['D0017'].includes(this.request.diplome.code), //Hide except DI
      isBachelor: this.isBachelor(),
      materiels: this.request.materiels || [],
      moyensNumeriques: this.request.moyensNumeriques || [],
      uniqueSpecialite: this.uniqueSpecialite || [],
      diplomeRequis: this.request.diplomeRequis || [],
      hasLicenceExcellence : this.request.licenceExcellence ? true : false,
      hasCentreExcellence : this.request.centreExcellence  ? true : false,
      partenariats: this.request.partenariats || [],
      coordonnateurTC : this.getCoordonateurTC(),
      hasPlacesPedagogiques: this.request.etablissement.type=="PUBLIC",
      matieresBac : Array.from(new Set(this.request.demandeHasSerieBacs.flatMap((serieBac) => {
        return serieBac.matieres.map((matiere) => {
          return this.request.langue === 'ARABE' ? matiere.libelleAr : matiere.libelle;
        });
      })))

    }

    console.log("Data", data)

    return data;
  }

  updateIntervenantsNatureIntervention(intervenants: { etablissements: any[]; universites: any[]; externes: any[]; consultants: any[]; }) {
    this.updateModulesNatureIntervention(intervenants.etablissements);
    this.updateModulesNatureIntervention(intervenants.universites);
    this.updateModulesNatureIntervention(intervenants.externes);
    this.updateModulesNatureIntervention(intervenants.consultants);
  }
  updateModulesNatureIntervention(modules: any[]) {
    modules.forEach((module: any) => {
      module.natureIntervention.cours = this.utilityService.getBoolean(module.natureIntervention.cours) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
      module.natureIntervention.td = this.utilityService.getBoolean(module.natureIntervention.td) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
      module.natureIntervention.tp = this.utilityService.getBoolean(module.natureIntervention.tp) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
      module.natureIntervention.encadrementStage = this.utilityService.getBoolean(module.natureIntervention.encadrementStage) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
      module.natureIntervention.encadrementProjet = this.utilityService.getBoolean(module.natureIntervention.encadrementProjet) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
      module.natureIntervention.autre = this.utilityService.getBoolean(module.natureIntervention.autre) ? (this.request.langue == 'ARABE' ?'نعم':'Oui' ) : false;
    });
  }

  /**
   * Summary. Generate docx report
   */
  async downloadDocxReport() {
    // Start loading
    this._helper.showLoading();

    //Generate docx blob object
    let blob = null


    if (this.isNouvelleLicence()){
      blob = await this.docxGenerator.createDocxReportAsBlob(this.initDescriptifData(), 'assets/docs/Desciptif_Licence_2023_' + (this.request.langue == 'ARABE' ? 'Ar' : 'Fr') + '.docx');
    }else if(this.isNewMaster()){
      if(this.isNewMasterPublic()){
        blob = await this.docxGenerator.createDocxReportAsBlob(this.initDescriptifData(), 'assets/docs/new-master/Descriptif_Master_2024_' + (this.request.langue == 'ARABE' ? 'Ar' : 'Fr') + '.docx');
      }else if(this.isNewMasterPrive()){
        blob = await this.docxGenerator.createDocxReportAsBlob(this.initDescriptifData(), 'assets/docs/new-master/Descriptif_Master_Prive_Fr_2024.docx');
      }
    }else{
      blob = blob = await this.docxGenerator.createDocxReportAsBlob(this.initDescriptifData(), 'assets/docs/Descriptif-Filiere-' + (this.request.langue == 'ARABE' ? 'Arabe' : 'Francais') + '.docx');
    }

    //Download docx report
    this.docxGenerator.saveDataToFile(blob, "Descriptif Filière: " + this.request.intituleAutre + ".docx");

    // Stop loading
    this._helper.hideLoading();

  }

  isNouvelleLicence(): boolean {
    return [CodeDiplome.NOUVELLE_LICENCE, CodeDiplome.BAC_PLUS_3, CodeDiplome.LICENCE_EXCELLENCE].includes(this.request.diplome.code)
  }

  isBachelor(): boolean {
    return (this.request.diplome.code === 'D0040') ? true : false;
  }

  private getTroncCommun() {
    if (!this.request.troncCommun && !this.request.troncCommunHaromnise)
      return null
    if (this.request.troncCommun) return this.request.troncCommun
    return this.request.langue == 'ARABE' ? this.request.troncCommunHaromnise.libelleAr : this.request.troncCommunHaromnise.libelle
  }

  private getCoordonateurTC() {
    if (!this.request.coordonateurTroncCommun) {
      return null
    }
    const ctc = this.request.coordonateurTroncCommun

    return this.request.langue == 'ARABE' ? {
      etablissement: ctc.etablissement ? ctc.etablissement.libelleAr : "",
      departement: ctc.departement ? ctc.departement.libelleAr : "",
      nom: ctc.prenomAr + ' ' + ctc.nomAr,
      grade: ctc.grade ? ctc.grade.libelleAr : ctc.autreGrade,
      diplome: ctc.diplome ? ctc.diplome.libelleAr : "",
      telephone:ctc.telephone,
      specialite:ctc.specialite,
      email:ctc.email
    } : {
      etablissement: ctc.etablissement ? ctc.etablissement.libelle : "",
      departement: ctc.departement ? ctc.departement.libelle : "",
      nom: ctc.prenom + ' ' + ctc.nom,
      grade: ctc.grade ? ctc.grade.libelle : ctc.autreGrade,
      diplome: ctc.diplome ? ctc.diplome.libelle : "",
      telephone:ctc.telephone,
      specialite:ctc.specialite,
      email:ctc.email

    }
  }

  isNewMaster(): boolean {
    return this.isNewMasterPublic() || this.isNewMasterPrive();
  }
  
  isNewMasterPublic(): boolean {
    return [CodeDiplome.NEW_MASTER_PUBLIC, CodeDiplome.MASTER_EXCELLENCE].includes(this.request.diplome.code)
  }
  isNewMasterPrive(): boolean {
    return [CodeDiplome.NEW_MASTER_PRIVE].includes(this.request.diplome.code)
  }
}
