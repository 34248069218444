import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Import SWAL alert
import swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { ModalAncienneFiliereComponent } from '../modals/modal-ancienne-filiere/modal-ancienne-filiere.component';


@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  @ViewChild("drawer", { static: false }) drawer;
  @Input() isOpen: boolean = false;
  _role: String;
  user: any = this.auth.getCurrentUser();

  constructor(
    public auth: AuthService,
    private modalService: NgbModal,
    private _elementRef: ElementRef
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this._elementRef.nativeElement.contains(targetElement) && targetElement.textContent != "menu") {
      this.isOpen = false;
    }
  }

  ngOnInit() {
  }

  confirmChoice() {
    swal.fire({
      title: false,
      text: "Voulez-vous créer une demande d'accréditation pour une ",
      icon: 'warning',
      width: '50%',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Nouvelle filière',
      denyButtonText: `Modification de la filière`,
      cancelButtonText: 'Ancienne filière'

    }).then((result: any) => {
      if (result.isConfirmed) {
        // this._router.navigate(["/accreditations/informations/new"]);
        location.href = "/accreditations/informations/new";
      } else if (result.isDenied) {
        location.href = "/accreditations/informations/new;modificationFiliere=1;ancien=1";
      }
      else if (result.dismiss == 'cancel') {
        //this.openModalAncienneFiliere();
        location.href = "/accreditations/informations/new;ancien=1";
      }
      else return false;
    })
  }

  openModalAncienneFiliere() {
    const modalRef = this.modalService.open(ModalAncienneFiliereComponent, { backdrop: 'static', size: 'lg', scrollable: true });

    modalRef.componentInstance.etablissementId = this.user.etablissementId;

    modalRef.result.then((result) => {
      location.href = "/accreditations/informations/new;ancien=1";
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
