import { Injectable } from "@angular/core";

@Injectable()
export class UtilityService {

    constructor() { }

    getBoolean(value) {
        switch (value) {
            case true:
            case "true":
            case 1:
            case "1":
            case "on":
            case "Oui":
            case "yes":
            case "نعم":
                return true;
            default:
                return false;
        }
    }

}
