import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import swal from 'sweetalert2';
import {Enseignant} from '../../../app.entities';
import {EnseignantComponent} from '../modals/enseignant/enseignant.component';
import {HelperService} from '../../../services/helper.service';
import {TranslatorService} from '../../../services/translator.service';
import {HttpService} from '../../../services/http.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


/**
 * This component is designed to allow the selection of an enseignant.
 * The component can also edit an existing one after it was selected/created
 * In order to validate the choice of the enseignant an input parameter (validate) is used to
 * control the visibility of the validation error message
 */
@Component({
  selector: 'app-enseignant-lookup',
  templateUrl: './enseignant-lookup.component.html',
  styleUrls: ['./enseignant-lookup.component.scss']
})
export class EnseignantLookupComponent implements OnInit {

  @Input()
  enseignant: Enseignant;

  // to allow a bidirectional binding [DO NOT RENAME IT]
  @Output()
  enseignantChange = new EventEmitter<Enseignant>();

  // label of the input eg: Responsable CEDOC, Coordonateur, etc.
  @Input()
  inputLabel: string;

  // controls the validation error message
  // when it's true and the enseignant is not defined the validation error will be visible
  @Input()
  validate: boolean;
  @Input()
  canEdit = true;
  @Input()
  readMode = false;


  constructor(
    private helper: HelperService,
    private translator: TranslatorService,
    private http: HttpService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    // otherwise, the translation service will fail, I don't want to go change in the service itself..
    this.translator.init();
  }

  findEnseignantByCin(cin: string) {
    this.helper.showLoading();
    if (!cin.match(/^[A-Za-z0-9]*$/)) {
      this.translator.get("errors").then((result: any) => {
        swal.fire({
          icon: 'warning',
          title: result.alert,
          text: result.modals.cinErr,
        });
      });
      this.helper.hideLoading();
      return;
    }
    this.http.get("enseignant-module/" + cin)
      .then((httpRequestResult: Enseignant) => {

        this.updateEnseignant(httpRequestResult);
        this.helper.hideLoading();

        this.translator.get("success").then((translation: any) => {
          swal.fire({
            icon: 'success',
            title: translation.title,
            text: translation.ensFd
          });
        });

      }).catch((error) => {
      this.helper.hideLoading();
      this.translator.get("errors").then((translation: any) => {
        swal.fire({
          icon: 'warning',
          title: translation.modals.cinNf1 + cin + translation.modals.cinNf2,
          text: translation.modals.ensNw,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          confirmButtonText: translation.modals.new,
          cancelButtonText: translation.modals.cancel
        }).then((result: any) => {
          if (result.value) {
            this.openNewEnseignantModal();
          } else {
            return false;
          }
        });
      });
    });
  }

  openNewEnseignantModal(editEnseignantFlag: boolean = false) {
    const modalRef = this.modalService.open(EnseignantComponent, {backdrop: 'static', size: 'lg', scrollable: true});

    let form_enseignant: FormGroup;

    if (editEnseignantFlag) {
      form_enseignant = this.buildEnseignantForm(this.enseignant);
    } else {
      form_enseignant = this.buildEnseignantForm(new Enseignant());
    }

    modalRef.componentInstance._form_enseignant = form_enseignant;

    modalRef.result.then((modalResult: FormGroup) => {
      this.updateEnseignant(modalResult.value as Enseignant);
      this.persisteNewEnseignant(this.enseignant, editEnseignantFlag);
    }, (reason) => {
      // console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  persisteNewEnseignant(enseignant: Enseignant, editEnseignantFlag: boolean = false) {

    this.helper.showLoading();
    if (editEnseignantFlag) {
      this.http.put("enseignants", enseignant)
        .then((putRequestResult: boolean) => {
          this.helper.hideLoading();
          if (!putRequestResult) {
            throw 'Une erreur est survenue lors de la mise à jour de l\'enseignant';
          }
          this.updateEnseignant(enseignant);
        })
        .catch(() => {
          this.translator.get("errors").then((result: any) => {
            this.helper.alert(result.title, result.global, 'error', result.ok);
          });
        });

    } else {

      this.http.post("enseignant", enseignant)
        .then((newEnseignantEntity: Enseignant) => {

          this.updateEnseignant(newEnseignantEntity);

          this.helper.hideLoading();
        })
        .catch(() => {
          this.translator.get("errors").then((result: any) => {
            this.helper.alert(result.title, result.global, 'error', result.ok);
          });
        });

    }
  }


  buildEnseignantForm(enseignant: Enseignant): FormGroup {

    let enseignantForm = {
      nom: new FormControl(enseignant.nom, Validators.required),
      prenom: new FormControl(enseignant.prenom, Validators.required),
      nomAr: new FormControl(enseignant.nomAr, Validators.required),
      prenomAr: new FormControl(enseignant.prenomAr, Validators.required),
      email: new FormControl(enseignant.email),
      telephone: new FormControl(enseignant.telephone),
      statut: new FormControl(enseignant.statut),
      cin: new FormControl(enseignant.cin, [Validators.required, Validators.minLength(3), Validators.maxLength(8), Validators.pattern(/^[A-Za-z0-9]*$/)]),
      numeroSom: new FormControl(enseignant.numeroSom),
      specialite: new FormControl(enseignant.specialite),
      grade: new FormControl(enseignant.grade),
      autreGrade: new FormControl(enseignant.autreGrade),
      etablissement: new FormControl(enseignant.etablissement),
      universite: new FormControl(null),
      organisme: new FormControl(enseignant.organisme),
      departement: new FormControl(enseignant.departement),
      diplome: new FormControl(enseignant.diplome),
      autreDepartement: new FormControl(enseignant.autreDepartement),
      appartient: new FormControl(enseignant.appartient)
    };
    if (enseignant.id) {
      enseignantForm['id'] = new FormControl(enseignant.id);
    }

    return new FormGroup(enseignantForm);
  }

  updateEnseignant(enseignant: Enseignant) {
    this.enseignant = enseignant;
    this.enseignantChange.emit(this.enseignant);
  }

}
