import { Routes } from '@angular/router';
import {AuthGuard, LoginGuard, RoleGuard} from './services/guards.service';

export const routes: Routes = [
    {
        path: '',
        loadChildren: './modules/home/home.module#HomeModule'
    },
    {
        path: 'access',
        loadChildren: './modules/access/access.module#AccessModule',
        canActivate: [LoginGuard]
    },
    {
        path: 'accreditations',
        loadChildren: './modules/accreditations/accreditations.module#AccreditationsModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_GESTIONNAIRE', 'ROLE_COORDONNATEUR', 'ROLE_RV', 'ROLE_RA', 'ROLE_CD', 'ROLE_RT', 'ROLE_RA_AN', 'ROLE_RT_AN'] }
    },
    {
        path: 'demandes-annulees',
        loadChildren: './modules/demandes-annulees/demandes-annulees.module#DemandesAnnuleesModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_COORDONNATEUR', 'ROLE_RV', 'ROLE_GESTIONNAIRE'] }
    },
    {
        path: 'coordonateurs',
        loadChildren: './modules/coordonateurs/coordonateurs.module#CoordonateursModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'initiateurs',
        loadChildren: './modules/initiateurs/initiateurs.module#InitiateursModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RV'] }
    },
    {
        path: 'users-aneaq',
        loadChildren: './modules/users-aneaq/users-aneaq.module#UsersAneaqModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN_AN'] }
    },
    {
        path: 'enseignants',
        loadChildren: './modules/enseignants/enseignants.module#EnseignantsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        loadChildren: './modules/users/users.module#UsersModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN', 'ROLE_RA'] }
    },
    {
        path: 'notifications',
        loadChildren: './modules/notifications/notifications.module#NotificationsModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN'] }
    },
    {
        path: 'dates',
        loadChildren: './modules/dates/dates.module#DatesModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN'] }
    },
    {
        path: 'situation-filiere',
        loadChildren: './modules/situation-filiere/situation-filiere.module#SituationFiliereModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ST'] }
    },
    {
        path: 'cnces-processing',
        loadChildren: './modules/cnces-processing/cnces-processing.module#CncesProcessingModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD', 'ROLE_RT'] }
    },
    {
        path: 'cnces-processing-recours',
        loadChildren: './modules/cnces-processing-recours/cnces-processing-recours.module#CncesProcessingRecoursModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD', 'ROLE_RT'] }
    },
    {
        path: 'expertise',
        loadChildren: './modules/expertise/expertise.module#ExpertiseModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA_AN', 'ROLE_RA', 'ROLE_CD', 'ROLE_RT', 'ROLE_RV', 'ROLE_GESTIONNAIRE', 'ROLE_COORDONNATEUR'] }
    },
    {
        path: 'etat-sortie',
        loadChildren: './modules/etat-sortie/etat-sortie.module#EtatSortieModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RV'] }
    },
    {
        path: 'choix-responsable-aneaq',
        loadChildren: './modules/choix-responsable-aneaq/choix-responsable-aneaq.module#ChoixResponsableAneaqModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA_AN'] }
    },
    {
        path: 'choix-responsable',
        loadChildren: './modules/choix-responsable/choix-responsable.module#ChoixResponsableModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD'] }
    },
    {
        path: 'validation-traitement',
        loadChildren: './modules/validation-traitement/validation-traitement.module#ValidationTraitementModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD'] }
    },
    {
        path: 'traitement',
        loadChildren: './modules/traitement/traitement.module#TraitementModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD', 'ROLE_RT'] }
    },
    {
        path: 'demandes',
        loadChildren: './modules/demandes/demandes.module#DemandesModule'
    },
    {
        path: 'demandes-expert',
        loadChildren: './modules/demandes-expert/demandes-expert.module#DemandesExpertModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_EXPERT'] }
    },
    {
        path: 'choix-expert',
        loadChildren: './modules/choix-expert/choix-expert.module#ChoixExpertModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA_AN,ROLE_RT_AN'] }
    },
    {
        path: 'experts',
        loadChildren: './modules/experts/experts.module#ExpertsModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN_AN'] }
    },
    {
        path: 'statistiques-cnces',
        loadChildren: './modules/statistiques-cnces/statistiques-cnces.module#StatistiquesCncesModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_RA', 'ROLE_CD', 'ROLE_RT', 'ROLE_RV', 'ROLE_GESTIONNAIRE', 'ROLE_ST'] }
    },
    {
        path: 'liaison-etablissement-diplome',
        loadChildren: './modules/liaison-etablissement-diplome/liaison-etablissement-diplome.module#LiaisonEtablissementDiplomeModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN', 'ROLE_RA'] }
    },
    {
        path: 'sessions',
        loadChildren: './modules/sessions/sessions.module#SessionsModule',
        canActivate: [AuthGuard],
        data: { allowedRoles: ['ROLE_ADMIN'] }
    },
    {
      path: 'cedoc',
      loadChildren: './modules/cedoc/cedoc.module#CedocModule',
      canActivate: [RoleGuard],
      data: { allowedRoles: ['ROLE_RV'] }
    }
]
