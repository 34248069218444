import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatorService {
    current_lang: string;

    constructor(private translate: TranslateService) { }

    init() {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem("lang", "fr");
            this.current_lang = "fr";
            document.getElementsByTagName("body")[0].classList.add("fr");
            this.translate.setDefaultLang("fr");
            this.translate.use("fr");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("fr");
            document.getElementsByTagName("body")[0].classList.remove("ar");
            document.getElementsByTagName("body")[0].classList.add(localStorage.getItem("lang"));
            this.translate.setDefaultLang(localStorage.getItem("lang"));
            this.translate.use(localStorage.getItem("lang"));
            this.current_lang = localStorage.getItem("lang");
        }

    }

    set_lang(lang: string) {
        localStorage.setItem("lang", lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        var element = document.querySelector("body");
        if (lang == 'fr') {
            element.classList.remove("ar");
            element.classList.add("fr");
        } else {
            element.classList.remove("fr");
            element.classList.add("ar");
        }
    }

    get(key: string) {
        return new Promise(resolve => {
            this.translate.get(key)
                .subscribe((res) => {
                    resolve(res);
                });
        })
    }

    instant(key: string): any {
        return this.translate.instant(key);
    }
}
