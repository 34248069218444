import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslatorService } from 'src/app/services/translator.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-ancienne-filiere',
  templateUrl: './modal-ancienne-filiere.component.html',
  styleUrls: ['./modal-ancienne-filiere.component.scss']
})
export class ModalAncienneFiliereComponent implements OnInit {

  form: FormGroup;

  diplomes: any[] = [];

  @Input()
  etablissementId: number;

  submitted: boolean = false;

  constructor(
    private _helper: HelperService,
    private _http: HttpService,
    public activeModal: NgbActiveModal,
    public _translator: TranslatorService,
    public _auth: AuthService
  ) {
    //Init form
    this.initForm();
  }

  ngOnInit() {
    // Init diplômes
    this.initDiplomes();
  }

  initDiplomes() {
    this._http.get("diplomesByEtab/" + this.etablissementId)
      .then((result: any) => {
        //Init Data
        this.diplomes = result;
      });
  }

  initForm() {
    this.form = new FormGroup({
      'diplome': new FormControl(null, [Validators.required]),
      'dateAccreditation': new FormControl(null, [Validators.required])
    });
  }

  closeModal() {
    if (this.conformityCheck() && this.dateCheck()) {
      this.activeModal.close(this.form);
    }
  }

  conformityCheck(): boolean {
    this.submitted = true;

    if (this.form.get('diplome').invalid || this.form.get('dateAccreditation').invalid) {
      swal.fire({
        icon: "warning",
        title: "Alerte!",
        text: "Veuillez vérifier les champs obligatoires",
      })
      return false;
    }
    return true;
  }

  dateCheck(): boolean {
    let dateAccreditation: Date = new Date(this.form.value.dateAccreditation);
    let dateFin = new Date(dateAccreditation.getFullYear() + this.form.value.diplome.dureeAccreditation, dateAccreditation.getMonth(), dateAccreditation.getDate());
    let today = new Date();
    if (dateFin > today) {
      swal.fire({
        icon: "warning",
        title: "Alerte!",
        text: "Selon la date que vous avez saisie, l'ancienne filière n'est pas encore expirée.",
      })
      return false;
    }
    return true;
  }

}
