import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeAr from '@angular/common/locales/ar';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatorService } from 'src/app/services/translator.service';
import { UtilityService } from 'src/app/services/utility.service';
import { element } from 'protractor';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeAr, 'ar');

@Component({
  selector: 'app-generate-pdf-description',
  templateUrl: './generate-pdf-description.component.html',
  styleUrls: ['./generate-pdf-description.component.scss']
})
export class GeneratePdfDescriptionComponent implements OnInit {

  _demandeId: any;
  _loaded: boolean = false;
  _semestres: any[] = [];
  _intervenants: any[] = [];
  _nature_modules: any = [];
  _current_lang: string = 'fr';
  departements = [];

  index_section_filiere: number = 7;

  @Input() request: any;
  @Input() dateImportante: any = {};

  constructor(
    public _route: ActivatedRoute,
    public _translator: TranslatorService,
    public utilityService: UtilityService
  ) {
    //Init demandeId
    if (this._route.snapshot.params.code) {
      this._demandeId = this._route.snapshot.params.code;
    }
  }

  ngOnInit() {
    //Init form lang
    this.initFormLangue();

    //Init semestres & intervenants
    this.initSemestres();
    this.initIntervenants();
    this.initDepartements();
  }

  initDepartements() {
    if (this.request.modules.length > 0) {
      this.request.modules.forEach(element => {
        if (element.departement) this.departements.push(element.departement)
      });
      this.departements = this.getUniqueListBy(this.departements, 'libelle');
    }
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
  /**
* Summary. Init form langue
*/
  initFormLangue() {
    // Check langue
    if (this.request.langue == 'ARABE') {
      //Set form lang
      this._translator.set_lang("ar");
      this._current_lang = 'ar';
    } else {
      //Set form lang
      this._translator.set_lang("fr");
      this._current_lang = 'fr';
    }
  }

  initSemestres() {
    const dureeIndexes = this._getDureeIndexes();

    //Parse through modules
    if (this.request.modules.length > 0) {
      for (let a = dureeIndexes.startIndex; a <= dureeIndexes.endIndex; a++) {
        let modules = this._getRequestModules(a);
        let semestre = {
          numeroSemestre: a,
          modules: modules
        }
        this._semestres.push(semestre);
      }
    }
  }

  initIntervenants() {
    //Parse through modules
    if (this.request.modules.length > 0) {
      for (let module of this.request.modules) {
        for (let intervenant of module.intervenants) {
          this.pushIntervenant(intervenant, module);
        }
        let intervenant = {
          enseignant: module.coordonnateurModule,
          modules: [module.intitule],
          natureIntervention: module.natureIntervention
        };
        this.pushIntervenant(intervenant, module);
      }
    }
  }

  /**
 * Summary. Push intervenant to intervenants list
 * @param intervenant Intervenant to be pushed
 * @param module Module related
 */
  pushIntervenant(intervenant: any, module: any) {
    //Init flag
    let found = false;
    let natureIntervantion: any = {};
    if (intervenant.id) {
      natureIntervantion = module.intervenants.find(elem2 => elem2.id = intervenant.id).natureIntervention;
    } else if (module.coordonnateurModule.id == intervenant.enseignant.id) {
      natureIntervantion = module.natureIntervention;
    }
    // Parse through intervenants
    for (let elem of this._intervenants) {
      if (intervenant.enseignant && elem.enseignant.id == intervenant.enseignant.id) {
        found = true;
        elem.modules.push({ id: module.id, intitule: module.intitule, natureIntervantion: natureIntervantion });
      }
    }
    //Check flag result
    if (!found && intervenant.enseignant) {
      //Init intervenant modules
      intervenant.modules = [{ id: module.id, intitule: module.intitule, natureIntervantion: natureIntervantion }];
      this._intervenants.push(intervenant);
    }
  }


  getNatureIntervenant(mi) {
    return mi.natureIntervantion;
  }

  getIntervenantTotalVH(intervenant) {
    let modules = this.request.modules;
    let vhCours = 0, vhTd = 0, vhTp = 0, vhEncadrementProjet = 0, vhEncadrementStage = 0;
    intervenant.modules.forEach(element => {
      let nbrIntervenantCours = 0, nbrIntervenantTd = 0, nbrIntervenantTp = 0, nbrIntervenantEncadrementProjet = 0, nbrIntervenantEncadrementStage = 0;

      let module = modules.find((module: any) => module.id === element.id);

      if (this.utilityService.getBoolean(module.natureIntervention.cours)) nbrIntervenantCours++;
      if (this.utilityService.getBoolean(module.natureIntervention.td)) nbrIntervenantTd++;
      if (this.utilityService.getBoolean(module.natureIntervention.tp)) nbrIntervenantTp++;
      if (this.utilityService.getBoolean(module.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
      if (this.utilityService.getBoolean(module.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;

      for (let inter of module.intervenants) {
        if (this.utilityService.getBoolean(inter.natureIntervention.cours)) nbrIntervenantCours++;
        if (this.utilityService.getBoolean(inter.natureIntervention.td)) nbrIntervenantTd++;
        if (this.utilityService.getBoolean(inter.natureIntervention.tp)) nbrIntervenantTp++;
        if (this.utilityService.getBoolean(inter.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
        if (this.utilityService.getBoolean(inter.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;
      }

      if (this.utilityService.getBoolean(intervenant.natureIntervention.cours))
        vhCours += this.getTotalVHCours(module) / nbrIntervenantCours;

      if (this.utilityService.getBoolean(intervenant.natureIntervention.td))
        vhTd += this.getTotalVHTd(module) / nbrIntervenantTd;

      if (this.utilityService.getBoolean(intervenant.natureIntervention.tp))
        vhTp += this.getTotalVHTp(module) / nbrIntervenantTp;

      if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementProjet))
        vhEncadrementProjet += this.getTotalVHEvaluationConnaissances(module) / nbrIntervenantEncadrementProjet;

      if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementStage))
        vhEncadrementStage += this.getTotalVHTravailPersonnel(module) / nbrIntervenantEncadrementProjet;
    });

    let total = vhCours + vhTd + vhTp + vhEncadrementProjet + vhEncadrementStage;
    return total.toFixed(1) + ' H';
  }

  getIntervenantVHByModule(intervenant, mod) {
    let modules = this.request.modules;
    let vhCours = 0, vhTd = 0, vhTp = 0, vhEncadrementProjet = 0, vhEncadrementStage = 0;

    let nbrIntervenantCours = 0, nbrIntervenantTd = 0, nbrIntervenantTp = 0, nbrIntervenantEncadrementProjet = 0, nbrIntervenantEncadrementStage = 0;

    let module = modules.find((module: any) => module.id === mod.id);

    if (this.utilityService.getBoolean(module.natureIntervention.cours)) nbrIntervenantCours++;
    if (this.utilityService.getBoolean(module.natureIntervention.td)) nbrIntervenantTd++;
    if (this.utilityService.getBoolean(module.natureIntervention.tp)) nbrIntervenantTp++;
    if (this.utilityService.getBoolean(module.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
    if (this.utilityService.getBoolean(module.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;

    for (let inter of module.intervenants) {
      if (this.utilityService.getBoolean(inter.natureIntervention.cours)) nbrIntervenantCours++;
      if (this.utilityService.getBoolean(inter.natureIntervention.td)) nbrIntervenantTd++;
      if (this.utilityService.getBoolean(inter.natureIntervention.tp)) nbrIntervenantTp++;
      if (this.utilityService.getBoolean(inter.natureIntervention.encadrementProjet)) nbrIntervenantEncadrementProjet++;
      if (this.utilityService.getBoolean(inter.natureIntervention.encadrementStage)) nbrIntervenantEncadrementStage++;
    }

    if (this.utilityService.getBoolean(intervenant.natureIntervention.cours))
      vhCours += this.getTotalVHCours(module) / nbrIntervenantCours;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.td))
      vhTd += this.getTotalVHTd(module) / nbrIntervenantTd;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.tp))
      vhTp += this.getTotalVHTp(module) / nbrIntervenantTp;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementProjet))
      vhEncadrementProjet += this.getTotalVHEvaluationConnaissances(module) / nbrIntervenantEncadrementProjet;

    if (this.utilityService.getBoolean(intervenant.natureIntervention.encadrementStage))
      vhEncadrementStage += this.getTotalVHTravailPersonnel(module) / nbrIntervenantEncadrementProjet;

    let total = vhCours + vhTd + vhTp + vhEncadrementProjet + vhEncadrementStage;
    return total.toFixed(1) + ' H';
  }

  isStageClinique(stage: any): boolean {
    return [11, 12].includes(stage.natureStage.id);
  }

  displayOptions(semestre: any): boolean {
    const test = semestre.numeroSemestre >= this.request.diplome.parcoursDebutSemestre ? true : false
    return test;
  }

  getEnseignantGradeLibelle(enseignant: any): string {
    return enseignant.grade ? (this.request.langue === 'ARABE' ? enseignant.grade.libelleAr : enseignant.grade.libelle) : enseignant.autreGrade;
  }

  getEnseignantDepartementLibelle(enseignant: any): string {
    return enseignant.departement ? (this.request.langue === 'ARABE' ? enseignant.departement.libelleAr : enseignant.departement.libelle) : enseignant.autreDepartement;
  }

  getEnseignantEtablissementLibelle(enseignant: any): string {
    return enseignant.etablissement ? (this.request.langue === 'ARABE' ? enseignant.etablissement.libelleAr : enseignant.etablissement.libelle) : enseignant.organisme;
  }

  moduleHasIntervenants(module: any): boolean {
    if (module.intervenants.length > 0) return true;
    return false;
  }

  getModulesByNature(nature: any): any[] {
    return this.request.modules.filter(module => module.natureModule.id === nature.id);
  }

  getVHGlobale(): number {
    const modules = this.request.modules;
    let vhGlobale = 0;
    for (let elem of modules) {
      const volumeHoraire = elem.volumeHoraire;
      vhGlobale += this.getVHGlobaleModule(volumeHoraire);
    }
    return vhGlobale;
  }

  getVHGlobaleNature(nature: any): number {
    const modules = this.getModulesByNature(nature);
    let vhGlobaleNature = 0;
    for (let elem of modules) {
      const volumeHoraire = elem.volumeHoraire;
      vhGlobaleNature += this.getVHGlobaleModule(volumeHoraire);
    }
    return vhGlobaleNature;
  }

  getIntervenantsEtablissementAttache(): any[] {
    if (this._intervenants.length > 0) {
      return this._intervenants.filter(intervenant => intervenant.enseignant && intervenant.enseignant.etablissement
        && intervenant.enseignant.etablissement.id === this.request.etablissement.id);
    }
    return [];
  }

  getIntervenantsAutresEtablissementsAppartientUniversite(): any[] {
    if (this._intervenants.length > 0) {
      return this._intervenants.filter(intervenant => intervenant.enseignant && intervenant.enseignant.etablissement
        && intervenant.enseignant.etablissement.id !== this.request.etablissement.id
        && intervenant.enseignant.etablissement.universite.id === this.request.etablissement.universite.id);
    }
    return [];
  }

  getIntervenantsExternesNotAppartientUniversite(): any[] {
    if (this._intervenants.length > 0) {
      return this._intervenants.filter(intervenant => intervenant.enseignant && intervenant.enseignant.etablissement
        && intervenant.enseignant.etablissement.id !== this.request.etablissement.id
        && intervenant.enseignant.etablissement.universite.id !== this.request.etablissement.universite.id);
    }
    return [];
  }

  getIntervenantsSocioeconomiques(): any[] {
    if (this._intervenants.length > 0) {
      return this._intervenants.filter(intervenant => intervenant.enseignant && !intervenant.enseignant.etablissement);
    }
    return [];
  }

  _getDureeIndexes(): any {
    if (["D0200"].includes(this.request.diplome.code)) {
      return {
        startIndex: 5,
        endIndex: 6
      }
    } else {
      return {
        startIndex: 1,
        endIndex: (this.request.diplome.duree * 2)
      }
    }
  }

  _getRequestModules(a: number): Array<any> {
    let filtredModules = this.request.modules.filter(module => module.numeroSemestre === a);
    return filtredModules;
  }

  containOption(module: any, option: any): boolean {
    let found = false;
    let options: any[] = module.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].id == option.id) {
        found = true;
        break;
      }
    }
    return found;
  }

  getEtablissementAndUniversite(etablissement: any, libelleStr: string): string {
    if (!etablissement) return "";

    if (etablissement.universitaire) {
      return etablissement[libelleStr] + " / " + etablissement.universite[libelleStr]
    } else {
      return etablissement[libelleStr];
    }
  }

  getOriginUrl(): string {
    return window.location.origin;
  }

  getVHGlobaleSemestre(modules: any[]): number {
    let vhGlobaleSemestre = 0;
    for (let elem of modules) {
      const volumeHoraire = elem.volumeHoraire;
      vhGlobaleSemestre += this.getVHGlobaleModule(volumeHoraire);
    }
    return vhGlobaleSemestre;
  }

  getVHGlobaleModule(volumeHoraire: any): number {
    let vhGlobaleModule = 0;
    if (volumeHoraire) {
      const composantsVolumeHoraire = volumeHoraire.composantsVolumeHoraire;
      for (let composant of composantsVolumeHoraire) {
        const vhGlobalComposant = this.getVHGlobaleComposant(composant);
        vhGlobaleModule += vhGlobalComposant;
      }
    }
    return vhGlobaleModule;
  }

  getVHGlobaleComposant(composant: any): number {
    let vhCours = this.getVhValue(composant.vhCours);
    let vhTp = this.getVhValue(composant.vhTp);
    let vhTd = this.getVhValue(composant.vhTd);
    let vhTravailPersonnel = this.getVhValue(composant.vhTravailPersonnel);
    let vhEvaluationConnaissances = this.getVhValue(composant.vhEvaluationConnaissances);
    return vhCours + vhTp + vhTd + vhTravailPersonnel + vhEvaluationConnaissances;
  }

  getVhValue(num: any): number {
    if ((num != null) && (num !== '') && !isNaN(Number(num.toString()))) {
      return num;
    }
    return 0;
  }

  getTotalVHCours(module: any): number {
    let totalVHCours = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalVHCours += element.vhCours;
    }
    return totalVHCours;
  }

  getPercentageVHCours(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHCours = this.getTotalVHCours(module);
    const percentage = (totalVHCours * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTd(module: any): number {
    let totalvhTd = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTd += element.vhTd;
    }
    return totalvhTd;
  }

  getPercentageVHTd(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHTd = this.getTotalVHTd(module);
    const percentage = (totalVHTd * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTp(module: any): number {
    let totalvhTp = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTp += element.vhTp;
    }
    return totalvhTp;
  }

  getPercentageVHTp(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHTp = this.getTotalVHTp(module);
    const percentage = (totalVHTp * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHTravailPersonnel(module: any): number {
    let totalvhTravailPersonnel = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhTravailPersonnel += element.vhTravailPersonnel;
    }
    return totalvhTravailPersonnel;
  }

  getPercentageVHTravailPersonnel(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHTravailPersonnel = this.getTotalVHTravailPersonnel(module);
    const percentage = (totalVHTravailPersonnel * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }

  getTotalVHEvaluationConnaissances(module: any): number {
    let totalvhEvaluationConnaissances = 0;
    const composants = module.volumeHoraire.composantsVolumeHoraire;
    for (let element of composants) {
      totalvhEvaluationConnaissances += element.vhEvaluationConnaissances;
    }
    return totalvhEvaluationConnaissances;
  }

  getPercentageVHEvaluationConnaissances(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const totalVHEvaluationConnaissances = this.getTotalVHEvaluationConnaissances(module);
    const percentage = (totalVHEvaluationConnaissances * 100) / vhGlobaleModule;
    return +percentage.toFixed() ? +percentage.toFixed() : 0;
  }


  getVHPresentiel(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const value = (module.vhPresentiel * vhGlobaleModule) / 100;
    return +value.toFixed() ? +value.toFixed() : 0;
  }

  getVHDistance(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const value = (module.vhDistance * vhGlobaleModule) / 100;
    return +value.toFixed() ? +value.toFixed() : 0;
  }

  getVHAlternance(module: any): number {
    const vhGlobaleModule = this.getVHGlobaleModule(module.volumeHoraire);
    const value = (module.vhAlternance * vhGlobaleModule) / 100;
    return +value.toFixed() ? +value.toFixed() : 0;
  }


  getPercentageVHGlobaleModeEnsModule(module: any): number {
    return module.vhPresentiel + module.vhDistance + module.vhAlternance;
  }

  getVHGlobaleModeEnsModule(module: any): number {
    const vhGlobalModule = this.getVHGlobaleModule(module.volumeHoraire);
    const vHGlobaleModeEnsModule = this.getPercentageVHGlobaleModeEnsModule(module);

    return (vHGlobaleModeEnsModule * vhGlobalModule) / 100;
  }

  async generatePDFDescription(): Promise<void> {
    const element: HTMLElement = document.getElementById('print-section');
    let myWindows = window.open('', 'PRINT', 'height=400,with=600');
    myWindows.document.write("<!DOCTYPE html>");
    if (this.request.langue == 'ARABE') {
      myWindows.document.write("<html dir='rtl' lang='ar'>");
    } else {
      myWindows.document.write("<html dir='ltr' lang='fr'>");
    }
    myWindows.document.write("<head>");
    myWindows.document.write("<meta charset='utf-8'>"
      + "<meta name='viewport' content='width=device-width, initial-scale=1'>"
      + "<style>@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');</style>"
      + "<style>@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');</style>"
      + "<style>@import url('http://fonts.cdnfonts.com/css/amazigh-motifs');</style>"
      + "<style>@import url('https://fonts.googleapis.com/earlyaccess/amiri.css');</style>");
    if (this.request.langue == 'ARABE') {
      myWindows.document.write("<link rel='stylesheet' type='text/css' href='" + this.getOriginUrl() + "/assets/css/direction-rtl.css'>");
    }
    myWindows.document.write("<link rel='stylesheet' type='text/css' href='" + this.getOriginUrl() + "/assets/css/generate-pdf-description.css'>");
    myWindows.document.write('</head><body>');
    myWindows.document.write(element.innerHTML);
    myWindows.document.write('</body></html>');
    myWindows.document.close();
    if (this.request.langue == 'ARABE') {
      myWindows.document.title = this.request.intituleAutre + " الملف الوصفي لطلب اعتماد مسلك";
    } else {
      myWindows.document.title = "Descriptif Filière " + this.request.intituleAutre;
    }
    await new Promise(resolve => setTimeout(resolve, 2500));
    myWindows.focus();
    myWindows.print();
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  hasOtherDepartementsDI(): boolean {
    return ['D0017', 'D1008', 'D0040', 'D1012'].includes(this.request.diplome.code);
  }

  hasDescriptionStages(): boolean {
    return ['D1003', 'D0099', 'D0017', 'D1008', 'D0008', 'D0012'].includes(this.request.diplome.code);
  }

  hasValidationAnnee(): boolean {
    return ['D1003', 'D0099', 'D0017', 'D1008'].includes(this.request.diplome.code);
  }

  hasValidation5emeSemestre(): boolean {
    return ['D0017', 'D1008'].includes(this.request.diplome.code);
  }

  hasArticulation(): boolean {
    // return ['D0017','D1008'].includes(this.request.diplome.code);
    return true;
  }

  hasBlocModules(): boolean {
    return ['D1003', 'D0099', 'D0017', 'D1008', 'D0016', 'D0008', 'D0009', 'D0012', 'D0037', 'D0038'].includes(this.request.diplome.code);
  }

  hasThese(): boolean {
    return ['D0008', 'D0009'].includes(this.request.diplome.code);
  }

  hasDiplomesRequis(): boolean {
    return !['D0008'].includes(this.request.diplome.code);
  }

  hasOptions(): boolean {
    return !["D1003", "D0099", "D0008", "D0009"].includes(this.request.diplome.code);
  }

  hasPrerequisPedagogique(): boolean {
    return !["D0016", "D0008", "D0009", "D0012"].includes(this.request.diplome.code);
  }

  hasProceduresSelection(): boolean {
    return !["D0016", "D0008", "D0009", "D0012"].includes(this.request.diplome.code);
  }

  hasEffectivePrevue(): boolean {
    return !["D0016", "D0008", "D0009", "D0012"].includes(this.request.diplome.code);
  }

  hasPasserelles(): boolean {
    return !['D0003', 'D0004', 'D0005'].includes(this.request.diplome.code);
  }

  hasDepartements(): boolean {
    return !["D0009", "D0008", "D0012"].includes(this.request.diplome.code);
  }

  isBachelor(): boolean {
    return (this.request.diplome.code === 'D0040') ? true : false;
  }

}
