import { Component, OnInit, Input } from '@angular/core';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
  selector: 'app-infos-enseignant',
  templateUrl: './infos-enseignant.component.html',
  styleUrls: ['./infos-enseignant.component.scss']
})
export class InfosEnseignantComponent implements OnInit {

  @Input()
  enseignant: any;

  constructor(
    public _translator: TranslatorService
  ) { }

  ngOnInit() {
    this._translator.init();
  }

}
